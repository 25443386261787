import React from "react";
import "../Pages/Contact.css";
import Navbar from "../Components/Navbar";
import MainFooter from "../Components/MainFooter";

const Contact = () => {
  const goToDown = () => {
    window.scrollTo({ top: 400, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="contact_section">
        <Navbar />
        <div className="contact">
          <div className="contact-line"></div>
          <div className="contact-text">
            <h1>
              Please get <br /> in touch{" "}
            </h1>
          </div>
        </div>

        {/* this is box in contact form  */}
        <div className="contact_box">
          <div className="con_btn">
            <button onClick={goToDown}>Learn More</button>
          </div>

          <div className="con_areya">
            <div className="text_con">
              <div className="con_areya_text1">
                <h1>Contact Us</h1>
              </div>
              <div className="con_areya_text2">
                <p>
                  We welcome the opportunity to review and discuss potential
                  investment <br /> opportunities with owners, management teams,
                  investment bankers, <br /> and other intermediaries and
                  professionals. <br /> For consideration and a timely response,{" "}
                  <br /> please contact:
                </p>
                <br /> <br />
                <h1>Bay Capital Investment Partners</h1>
                <br /> <br />
                <h2>
                  One Turks Head Place, Suite 503 <br />
                  Providence, RI 02903 <br />
                  P (401) 396-0930 <br />F (401) 228-3835
                </h2>
              </div>
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    </>
  );
};

export default Contact;
