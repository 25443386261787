import React from 'react';
import "../Pages/InvestmentCa.css";
import Navbar from '../Components/Navbar';
import MainFooter from '../Components/MainFooter';

const InvestmentCa = () => {
  return (
    <>
      <div className="investment">
        <Navbar/>
        <div className="invest_heading">
          <h2>INVESTMENT CRITERIA</h2>
        </div>
        <div className="invest_head">
          <p>Most of the situations we consider have a capital need in the $5-$20 million range.</p>
          <h3>We have a history of co-investing and can consider larger funding needs. The opportunities we favor require senior or subordinated debt as the primary capital need but we also routinely invest equity in addition to debt capital. We are flexible in our investment approach but the companies we partner with generally have the following characteristics:</h3>
        </div>

        <div className="invest_item">
          <div className="item_left">
            <span>Revenue - $10-$100 million</span>
            <span>EBITDA   - $2-$15 million</span>
            <span>Sustainable positive cash flow</span>
            <span>Proven business strategy</span>
            <span>Experienced and committed management</span>
          </div>
          <div className="item_right">
            <p>We will look at opportunities nationwide, but spend most of our time, east of the Rockies. We are often an alternative to traditional bank financing as we are experienced “cash flow” lenders, comfortable in industries which may be “asset light”, such as healthcare, business services, information technology, consumer, media and entertainment.</p>
          </div>
        </div>
      </div>

      <MainFooter/>
    </>
  )
}

export default InvestmentCa