const MenuItems = [
    {
        title: 'Current Investments',
        path: '../curenteinvestment'
    },
    {
        title: 'Realized Investments',
        path: '../relizedInvestments'
    }
]

export default MenuItems

