import React, { useEffect } from 'react';
import "../Components/PreLoader.css";
import { preLoaderAnim } from "../animations";
import img from "../Imges/Bay Capital.png"


const PreLoader = () => {

    useEffect(() => {
        preLoaderAnim();
    }, []);


    return (
        <div className="preloader">
            <div className="texts-container">
                <span>
                    <img src={img} alt="logo" />
                </span>
            </div>
        </div>
    )
}

export default PreLoader