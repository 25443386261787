import React from "react";
import "../Pages/News.css";
import Navbar from "../Components/Navbar";
import MainFooter from "../Components/MainFooter";

const News = () => {
  return (
    <>
    <Navbar/>
      <div className="news">
        <div className="news_heading">
          <h1>Blog News & Announcements</h1>
        </div>
        <div className="news_box">
          <iframe allowFullScreen="allowfullscreen" title="News Blogs"  className="fp-iframe" src="https://heyzine.com/flip-book/490746eb08.html" frameborder="0"></iframe>
        </div>
        <div className="news_box">
          <iframe allowFullScreen="allowfullscreen" title="News Blogs" className="fp-iframe" src="https://heyzine.com/flip-book/85def1d5da.html" frameborder="0"></iframe>
        </div>
        <MainFooter/>
      </div>
    </>
  );
};

export default News;
