import React from "react";
import "../Components/MainFooter.css";
import { Link } from "react-router-dom";

const MainFooter = () => {
  return (
    <>
      <div className="MainFooter">
        <div className="MainFooter_text">
          <p>© 2023 Bay Capital Investment Partners. All Rights Reserved</p>
        </div>
        <div className="item_link">
          <Link to="/">Home</Link>
          <Link to="/termsConditions">Terms & Conditions</Link>
          <Link to="/policy">Privacy Policy</Link>
        </div>
      </div>
    </>
  );
};

export default MainFooter;
