import React from "react";
import "../Pages/RelizedInvestments.css";
import Navbar from "../Components/Navbar";
import { Link } from "react-router-dom";
import img1 from "../Imges/r1.png";
import img2 from "../Imges/r2.png";
import img3 from "../Imges/r3.png";
import img4 from "../Imges/r4.png";
import img5 from "../Imges/r5.png";
import img6 from "../Imges/r6.jpg";
import img7 from "../Imges/a1.jpg";
import img8 from "../Imges/r8.png";
import img9 from "../Imges/r9.png";
import img10 from "../Imges/r10.png";
import img11 from "../Imges/r11.jpg";
import img12 from "../Imges/r12.png";
import img13 from "../Imges/r13.jpg";
import img14 from "../Imges/r14.png";
import img15 from "../Imges/r15.png";
import img16 from "../Imges/r16.png";
import img17 from "../Imges/r17.png";
import img18 from "../Imges/r18.png";
import img19 from "../Imges/r19.png";
import img20 from "../Imges/r20.png";
import img21 from "../Imges/r21.png";
import img22 from "../Imges/r22.jpg";
import img23 from "../Imges/r23.png";
import img24 from "../Imges/r24.jpg";
import img25 from "../Imges/r25.png";
import img26 from "../Imges/r26.png";
import img27 from "../Imges/r27.png";
import img28 from "../Imges/r28.png";
import img29 from "../Imges/r29.gif";
import img30 from "../Imges/r30.png";
import img31 from "../Imges/r31.jpg";
import img32 from "../Imges/r32.png";
import img35 from "../Imges/r35.png";
import img34 from "../Imges/r34.gif";
import imgf1 from "../Imges/f1.png";
import MainFooter from "../Components/MainFooter";

const RelizedInvestments = () => {
  return (
    <>
      <Navbar />
      <div className="relizedinvestment">
        <div className="relized_heading">
          <h2>Realized Investments</h2>
        </div>

        <div className="cur_box">
          <div className="box_2">
            <div className="box_text2">
              <h2>Artifact Uprising</h2>
              <br />
              <Link to="https://www.artifactuprising.com/">
                artifactuprising.com
              </Link>
              <span>Denver, CO</span>
              <br />
              <p>
                Artifact Uprising is an online provider of premium quality
                custom photo goods that creates tangible photo books, prints,
                and gifts for digital photos. Signature products include premium
                quality photo books whose interior pages are printed on 100%
                recycled paper and a collection of wooden products handcrafted
                with locally sourced reclaimed wood.
              </p>
            </div>

            <div className="box_img2">
              <img src={img7} alt="" />
            </div>
          </div>

          {/* 1  */}
          <div className="box_1">
            <div className="box_img">
              <img src={img1} alt="" />
            </div>
            <div className="box_text">
              <h2>48HourPrint.com</h2>
              <br /> <br />
              <Link to="http://www.48hourprint.com/">artifactuprising.com</Link>
              <span>Boston, MA</span>
              <br /> <br />
              acquired by Digital Room in April 2016, was a leading online
              supplier of high-quality customized printed products to small
              businesses nationally.
            </div>
          </div>
          {/* 2  */}
          <div className="box_2">
            <div className="box_text2">
              <h2>Access Northeast</h2>
              <br />
              <Link to="http://www.xand.com/">Xand.com</Link>
              <span>Marlborough, MA</span>
              <br />
              <p>
                Access Northeast, acquired by Xand Holdings in March, 2012, was
                a managed data services and co-location facilities provider,
                providing data center space, WAN, disaster recovery, and
                Internet services to enterprise customers.
              </p>
            </div>

            <div className="box_img2">
              <img src={img2} alt="" />
            </div>
          </div>

          {/* 3  */}
          <div className="box_1">
            <div className="box_img">
              <img src={img3} alt="" />
            </div>
            <div className="box_text">
              <h2>American Broadband and Telecommunications Co.</h2>
              <br /> <br />
              <Link to="http://www.ambt.net/">Ambt.com</Link>
              <span>Toledo, OH</span>
              <br /> <br />
              <p>
                American Broadband is a provider of advanced telephone and
                internet services to residential and business customers in Ohio
                and Michigan. Fully exited in May, 2011
              </p>
            </div>
          </div>

          {/* 4  */}
          <div className="box_2">
            <div className="box_text2">
              <h2>American EMS</h2>
              <br />
              <Link to="http://www.american-ems.com/">American-EMS.com</Link>
              <span>Mansfield, OH</span>
              <br />
              <p>
                American EMS provides emergency and non-emergency medical
                transport services to medical facilities, nursing homes, and
                municipalities in Ohio.
              </p>
            </div>

            <div className="box_img2">
              <img src={img4} alt="" />
            </div>
          </div>

          {/* 5  */}
          <div className="box_1">
            <div className="box_img">
              <img src={img5} alt="" />
            </div>
            <div className="box_text">
              <h2>Aras Corporation</h2>
              <br /> <br />
              <Link to="http://www.aras.com/">Aras.com</Link>
              <span>Andover, MA</span>
              <br /> <br />
              <p>
                Aras is a developer and provider of product lifecycle management
                ("PLM") software. The company's software platform, Aras
                Innovator, allows its customers to manage complex products and
                processes over the entire product lifecycle from concept through
                end of life, working across functions and supply chain partners.
              </p>
            </div>
          </div>

          {/* 6  */}
          <div className="box_2">
            <div className="box_text2">
              <h2>Certica Solutions</h2>
              <br />
              <Link to="http://www.certicasolutions.com/">
                CerticaSolutions.com
              </Link>
              <span>Wakefield, MA</span>
              <br />
              <p>
                Certica Solutions, acquired by New Harbor Capital in February
                2016, provides data management and education content solutions
                which enable K-12 educators and administrators to assess,
                measure, and improve instructional and organizational
                performance.
              </p>
            </div>

            <div className="box_img2">
              <img src={img6} alt="" />
            </div>
          </div>

          {/* 8 */}

          <div className="box_1">
            <div className="box_img">
              <img src={imgf1} alt="" />
            </div>
            <div className="box_text">
              <h2>First Water Systems</h2>
              <br /> <br />
              <Link to="http://www.aras.com/">FirstWaterInc.com</Link>
              <span>Atlanta, GA</span>
              <br /> <br />
              <p>
                First Water Systems provides a complete line of proprietary
                portable and permanent water purification solutions to hospitals
                and healthcare organizations, government agencies, universities,
                and the emergency management industry.
              </p>
            </div>
          </div>

          <div className="box_2">
            <div className="box_text2">
              <h2>Claris Vision</h2>
              <br />
              <Link to="http://www.kocheye.com/">ClarisVision.com</Link>
              <span>Warwick, RI</span>
              <br />
              <p>
                Claris Vision, acquired by Eli Global in December 2017, was one
                of the largest providers of optometry and ophthalmology services
                in New England, operating from 12 locations including 2
                company-owned surgery centers.
              </p>
            </div>

            <div className="box_img2">
              <img src={img8} alt="" />
            </div>
          </div>

          {/* 9  */}
          <div className="box_1">
            <div className="box_img">
              <img src={img9} alt="" />
            </div>
            <div className="box_text">
              <h2>Source Code (Comark)</h2>
              <br /> <br />
              <Link to="http://www.comarkcorp.com/">Comark.com</Link>
              <span>Milford, MA</span>
              <br /> <br />
              <p>
                Comark designs and manufactures industrial computing system
                solutions built to operate reliably in environments that
                compromise most display terminals and computers. These systems
                are installed in military, marine, industrial, and commercial
                applications.
              </p>
            </div>
          </div>

          {/* 10  */}
          <div className="box_2">
            <div className="box_text2">
              <h2>ControlCam</h2>
              <br />
              <Link to="http://www.controlcam.com/">ControlCam.com</Link>
              <span>Jacksonville, FL</span>
              <br />
              <p>
                ControlCam, acquired by Verisk Analytics in April 2017, was a
                developer of imaging technologies that served markets
                encompassing real estate sales, appraisal, mortgage lending,
                insurance, GIS and government agencies with a need for high
                quality aerial imagery.
              </p>
            </div>

            <div className="box_img2">
              <img src={img10} alt="" />
            </div>
          </div>

          {/* 11  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img11} alt="" />
            </div>
            <div className="box_text">
              <h2>Flo-Tech</h2>
              <br /> <br />
              <Link to="http://www.flotech.net/">FloTech.net</Link>
              <span>Middletown, CT</span>
              <br /> <br />
              <p>
                Flo-Tech, acquired by FlexPrint in December 2017, was a provider
                of managed print services and hardware and supplies to
                businesses for their printing and imaging needs.
              </p>
            </div>
          </div>

          {/* 12  */}
          <div className="box_2">
            <div className="box_text2">
              <h2>Intac International</h2>
              <br />
              <Link to="http://www.intacinternational.com/">
                IntacInternational.com
              </Link>
              <span>Burlington, MA</span>
              <br />
              <p>
                Intac International, acquired by DavisWare in October 2017, was
                the developer of Wintac, a fully integrated ERP (Enterprise
                Resource Planning)/business automation software for small to
                medium-sized field service, installation and construction
                companies.
              </p>
            </div>

            <div className="box_img2">
              <img src={img12} alt="" />
            </div>
          </div>

          {/* 13  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img13} alt="" />
            </div>
            <div className="box_text">
              <h2>Oliver Street Dermatology Holdings</h2>
              <br /> <br />
              <Link to="http://www.kcdermcenter.com/">
                DermatologyTyler.com
              </Link>
              <span>Dallas, TX</span>
              <br /> <br />
              <p>
                Oliver Street, acquired by Abry Partners in May 2016, was a
                holding company that owned Dermatology Associates of Tyler,
                Texas and Dermatology and Skin Cancer Center of Kansas City,
                Missouri which are providers of clinical dermatology and
                pathology lab services.
              </p>
            </div>
          </div>

          {/* 14  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>Dover Saddlery</h2>
              <br />
              <Link to="http://www.doversaddlery.com/">DoverSaddlery.com</Link>
              <span>Littleton, MA</span>
              <br />
              <p>
                Dover Saddlery, acquired by Webster Capital in July 2015, is the
                largest direct marketer and leading retailer of premium
                equestrian products in the U.S.
              </p>
            </div>

            <div className="box_img2">
              <img src={img14} alt="" />
            </div>
          </div>

          {/* 15  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img15} alt="" />
            </div>
            <div className="box_text">
              <h2>Northvale, NJ</h2>
              <br /> <br />
              <Link to="http://www.paradestore.com/">ParadeStore.com</Link>
              <span>Milford, MA</span>
              <br /> <br />
              <p>
                Glendale Parade Stores is a direct marketer of ceremonial
                uniform and parade accessories.
              </p>
            </div>
          </div>

          {/* 16  */}
          <div className="box_2">
            <div className="box_text2">
              <h2>TheLadders</h2>
              <br />
              <Link to="http://www.theladders.com/">TheLadders.com</Link>
              <span>New York, NY</span>
              <br />
              <p>
                TheLadders.com is a specialty employment website focusing on
                sourcing $100k+ jobs.
              </p>
            </div>

            <div className="box_img2">
              <img src={img16} alt="" />
            </div>
          </div>

          {/* 17  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img17} alt="" />
            </div>
            <div className="box_text">
              <h2>mdHub</h2>
              <br /> <br />
              <Link to="http://mdhub.com/">mdHub.com</Link>
              <span>Darien, CT</span>
              <br /> <br />
              <p>
                mdHub was acquired by Galen Partners in November 2010 and was a
                leading health marketing and data services company serving
                health care organizations.
              </p>
            </div>
          </div>

          {/* 18  */}
          <div className="box_2">
            <div className="box_text2">
              <h2>Nexamp</h2>
              <br />
              <Link to="http://www.nexamp.com/">nexamp.com</Link>
              <span>Boston, MA</span>
              <br />
              <p>
                Nexamp, Inc. is a market leader in delivering turnkey,
                utility-scale solar power solutions.
              </p>
            </div>

            <div className="box_img2">
              <img src={img18} alt="" />
            </div>
          </div>

          {/* 19  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img19} alt="" />

              <img src={img20} alt="" />
            </div>
            <div className="box_text">
              <h2>Quantem Aviation Services</h2>
              <h2>Integrated Deicing Services</h2>
              <br /> <br />
              <Link to="http://www.qasllc.aero/">qasllc.aero</Link>
              <Link to="http://www.deicingsolutions.com/">
                DeicingSolutions.com
              </Link>
              <span>Manchester, NH</span>
              <br /> <br />
              <p>
                Acquired by Inland Technologies in July 2015, Quantem was a
                leading provider of air cargo handling and services to carriers
                in 18 U.S. locations, and IDS was a leading provider of
                innovative airplane deicing services to U.S. carriers in several
                U.S. and European airport locations.
              </p>
            </div>
          </div>

          {/* 20  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>Renovation Brands</h2>
              <br />
              <Link to="https://www.renovationbrands.com/">
                renovationBrands.com
              </Link>
              <span>Florence, AL</span>
              <br />
              <p>
                Renovation Brands is a manufacturer and online retailer of home
                decor and building improvement products including fireplace
                mantels, decorative ceiling and wall tiles, floor and wall
                registers and electric fireplaces.
              </p>
            </div>

            <div className="box_img2">
              <img src={img21} alt="" />
            </div>
          </div>

          {/* 21  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img22} alt="" />
            </div>
            <div className="box_text">
              <h2>RiteAir</h2>
              <br /> <br />
              <Link to="http://www.riteairmechanical.com/">
                Riteairmechanical.com
              </Link>
              <span>Bellmawr, NJ</span>
              <br /> <br />
              <p>
                Rite-Air is a commercial HVAC & Refrigeration maintenance and
                service company serving the Mid-Atlantic region.
              </p>
            </div>
          </div>

          {/* 22  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>Roadrunner Restoration</h2>
              <br />
              <Link to="http://www.roadrunnerrestoration.com/profile.php">
                RoadRunnerRestoration.com
              </Link>
              <span>Houston, TX</span>
              <br />
              <p>
                Roadrunner Restoration is a disaster recovery/mitigation and
                restoration services provider to residential and commercial
                property owners.
              </p>
            </div>

            <div className="box_img2">
              <img src={img23} alt="" />
            </div>
          </div>

          {/* 23  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img24} alt="" />
            </div>
            <div className="box_text">
              <h2>RYZE Claim Solutions</h2>
              <br /> <br />
              <Link to="https://ryzeclaims.com/">www.RyzeClaims.com</Link>
              <span>New York, NY</span>
              <br /> <br />
              <p>
                RYZE Claim Solutions is a full service property and casualty
                insurance claim adjusting firm providing many claim management
                functions such as (i) claim adjusting (ii) third party
                administration (iii) subrogation and (iv) catastrophe claims.
              </p>
            </div>
          </div>

          {/* 24  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>Sequest Technologies</h2>
              <br />
              <Link to="http://www.sequest.net/">Sequest.net</Link>
              <span>Lisle, IL</span>
              <br />
              <p>
                Sequest Technologies, acquired by NetSmart, Inc. in October
                2011, was a leading provider of Electronic Medical Record (EMR)
                software solutions for the health and human services industry.
                Fully exited in October, 2011.
              </p>
            </div>

            <div className="box_img2">
              <img src={img25} alt="" />
            </div>
          </div>

          {/* 25  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img26} alt="" />
            </div>
            <div className="box_text">
              <h2>Outdoor Adventure Brands (Summit Sports)</h2>
              <br /> <br />
              <Link to="https://www.the-house.com/summit-sports?utm_source=summitsports_com&utm_medium=redirect&utm_campaign=summit-redirect">
                SummitSports.com
              </Link>
              <span>Bloomfield Hills, MI</span>
              <br /> <br />
              <p>
                Summit Sports is a multi-channel retailer of action sports
                equipment and apparel, operating the websites Skis.com,
                Snowboards.com, Inlineskates.com, Wateroutfitters.com,
                Campgear.com, and austinkayak.com.
              </p>
            </div>
          </div>

          {/* 26  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>Sunrise Transportation Holdings</h2>
              <br />
              <Link to="https://www.sunrisebus.com/">SunRiseBus.com</Link>
              <span>Chicago, IL</span>
              <br />
              <p>
                Sunrise Transportation is a provider of student transportation
                in the Chicago, IL and Hartford, CT areas with a focus on
                special needs students.
              </p>
            </div>

            <div className="box_img2">
              <img src={img27} alt="" />
            </div>
          </div>

          {/* 27  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img28} alt="" />
            </div>
            <div className="box_text">
              <h2>Tel-Instrument Electronics Corp.</h2>
              <br /> <br />
              <Link to="http://www.telinst.com/">Telinst.com</Link>
              <span>East Rutherford, NJ</span>
              <br /> <br />
              <p>
                Tel-Instrument (TIK: AMEX) is a leader in the design and
                manufacture of avionics test equipment for commercial and
                military customers.
              </p>
            </div>
          </div>

          {/* 28  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>Thermacore, Inc.</h2>
              <br />
              <Link to="http://www.thermacore.com/">Thermacore.com</Link>
              <span>Lancaster, PA</span>
              <br />
              <p>
                Thermacore, acquired by Aavid Thermalloy in October 2016, is a
                market leader in the custom design, development, and manufacture
                of highly engineered thermal management systems and components
                for a variety of OEM applications.
              </p>
            </div>

            <div className="box_img2">
              <img src={img29} alt="" />
            </div>
          </div>

          {/* 29  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img30} alt="" />
            </div>
            <div className="box_text">
              <h2>Truist</h2>
              <br /> <br />
              <Link to="https://www.truist.com/">Truist.com</Link>
              <span>Washington, DC</span>
              <br /> <br />
              <p>
                Truist Inc., acquired by FrontStream Holdings in February 2014,
                was a leading provider to corporations and nonprofit
                organizations with a comprehensive line of products and services
                to manage charitable campaigns.
              </p>
            </div>
          </div>

          {/* 30  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>Venture Technologies</h2>
              <br />
              <Link to="http://www.ventech.com/">VenTech.com</Link>
              <span>Ridgeland, MS</span>
              <br />
              <p>
                Venture Technologies offers a diversified set of IT solutions
                including product procurement and sales, cloud and hosted
                solutions, and professional services.
              </p>
            </div>

            <div className="box_img2">
              <img src={img31} alt="" />
            </div>
          </div>

          {/* 31  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img32} alt="" />
            </div>
            <div className="box_text">
              <h2>V12 Data</h2>
              <br /> <br />
              <Link to="https://www.datamentors.com/">DataMentors.com</Link>
              <span>Wesley Chapel, FL</span>
              <br /> <br />
              <p>
                V12 Data is a provider of omnichannel marketing data and data
                services.
              </p>
            </div>
          </div>

          {/* 32  */}

          <div className="box_2">
            <div className="box_text2">
              <h2>WorldAPP</h2>
              <br />
              <Link to="https://www.form.com/">WorldApp.com</Link>
              <span>Braintree, MA</span>
              <br />
              <p>
                WorldAPP provides data collection and business process
                management software solutions to enterprise customers.
              </p>
            </div>

            <div className="box_img2">
              <img src={img34} alt="" />
            </div>
          </div>

          {/* 33  */}

          <div className="box_1">
            <div className="box_img">
              <img src={img35} alt="" />
            </div>
            <div className="box_text">
              <h2>Xand</h2>
              <br /> <br />
              <Link to="http://www.xand.com/">Xand.com</Link>
              <span>Hawthorne, NY</span>
              <br /> <br />
              <p>
                Xand, acquired by TierPoint in December 2014, was a leading
                facilities-based provider of data center managed services and
                technology solutions.
              </p>
            </div>
          </div>

          {/* end  */}
        </div>
      </div>

      <MainFooter />
    </>
  );
};

export default RelizedInvestments;
