import React from "react";
import "../../Components/Login/Login.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../Context/UserAuthContext";
import { useState } from "react";
import swal from "sweetalert";
import Navbar from "../Navbar";
import MainFooter from "../MainFooter";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      swal("Login Successful!");
      navigate("/disclaimer");
    } catch (error) {
      setError(error.message);
    }
  };
  

  return (
    <>
      <div className="login_section">
        <Navbar />
        <div className="heading">
          <h1>login Your Investor account</h1>
        </div>
        <div className="login_container">
          <form onSubmit={handleSubmit}>
            <div className="login_heading">
              <h1>Log in</h1>
              <p>to your investor account</p>
              <span>{error}</span>
            </div>

            <div className="inp">
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <br />
              <button type="submit">Sign In</button>
            </div>

            <div className="btn">
              <span>
                Don't have an account? <Link to="/signup">SignUp</Link>
              </span>
            </div>
          </form>
        </div>
       
        <MainFooter />
      </div>
    </>
  );
};

export default Login;
