import React from 'react';
import AnimatedCursor from 'react-animated-cursor';

const Animation = () => {
  return (
    <AnimatedCursor
    innerSize={40}
    outerSize={10}
    color='92, 135, 230'
    outerAlpha={5}
    innerScale={1}
    outerScale={0.2}
    clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
    />
  )
}

export default Animation