import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Animation from "./Components/Animation";
import PreLoader from "./Components/PreLoader";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import InvestorLogin from "./Pages/InvestorLogin";
import Team from "./Pages/Team";
import News from "./Pages/News";
import Login from "./Components/Login/Login";
import Signup from "./Components/Signup/Signup";
import { UserAuthContextProvider } from "./Context/UserAuthContext";
import Disclaimer from "./Pages/Disclaimer/Disclaimer";
import TermsConditions from "./Components/TermsConditions";
import Policy from "./Components/Policy";
import Show from "./Pages/InvestorPDF/Show";
import InvestmentCa from "./Pages/InvestmentCa";
import Protfilo from "./Pages/Protfilo";
import Curenteinvestment from "./Pages/Curenteinvestment";
import RelizedInvestments from "./Pages/RelizedInvestments";
import Fundmodel from "./Pages/Fundmodel";
import Transactiondescription from "./Pages/Transactiondescription";


function App() {
  return (
    <>
      <UserAuthContextProvider>
        <PreLoader />
        <Animation />
        <div className="app">
          <BrowserRouter>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/investorLogin" element={<InvestorLogin />} />
              <Route path="/team" element={<Team />} />
              <Route path="/news" element={<News />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/disclaimer" element={<Disclaimer/>} />
              <Route path="/termsConditions" element={<TermsConditions/>} />
              <Route path="/policy" element={<Policy/>} />
              <Route path="/show" element={<Show/>} />
              <Route path="/investment" element={<InvestmentCa/>} />
              <Route path="/protfilo" element={<Protfilo/>} />
              <Route path="/curenteinvestment" element={<Curenteinvestment/>} />
              <Route path="/relizedInvestments" element={<RelizedInvestments/>} />
              <Route path="/fund_model" element={<Fundmodel/>} />
              <Route path="/transactiondescription" element={<Transactiondescription/>} />
            </Routes>
          </BrowserRouter>
        </div>
      </UserAuthContextProvider>
    </>
  );
}

export default App;
