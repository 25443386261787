import React from "react";
import "../Disclaimer/Disclaimer.css";
import { Link } from "react-router-dom";
// import Login from "../../Components/Login/Login";
import { useNavigate } from "react-router-dom";

const Disclaimer = () => {
  const navigate = useNavigate();

  const handleAgreeClick = () => {
    window.scrollTo(0, 0);
    navigate("/show");
  };

  return (
    <>
      <div className="disclaimer">
        <h1>Disclaimer</h1>
        <br />
        <h2>NON-DISCLOSURE AGREEMENT</h2>
        <br />
        <p>
          THIS NON-DISCLOSURE AGREEMENT (this “Agreement”) is made BY AND
          BETWEEN
        </p>
        <br />
        <p>
          (1) Bay Capital Partners, a company incorporated and existing under
          the laws of the United States of America with registration number
          88-2767181 with representative office at PO Box 540, Denver, CO 80201
        </p>
        <br />
        <h2>AND</h2>
        <br />
        <p>
          (2)The users who access Bay Capital Partners investor section and all
          its substituent URLs by clicking on ‘Agree and Continue’ below and
          accepting this agreement collectively referred to as “Parties” and
          individually as “Party”. In this Agreement, each Party which discloses
          Confidential Information (as defined below) is hereinafter referred to
          as “Discloser” and each Party which receives Confidential Information
          (as defined below) is hereinafter referred to as “Recipient”.
        </p>
        <br />
        <h2>IT IS AGREED as follows:</h2>
        <br />
        <p>
          PREAMBLE: The Parties wish to discuss and explore the following
          matters:
        </p>
        <br />
        <p>
          (a) Bay Capital Partners is a private company working on raising
          capital for a private fund (the “Fund”). The objective of this Fund is
          to raise capital to be invested in listed and unlisted real estate.
        </p>
        <br />
        <p>
          (b) The Parties wish to discuss and explore a potential investment,
          partnership, advisory and consulting related to the Fund hereinafter
          referred to as the “Projects”.
        </p>
        <br />
        <p>
          1) CONFIDENTIALITY: For the purpose of the evaluation of the Projects,
          the Parties share and disclose to each other Confidential Information
          (as defined in this Agreement). “Confidential Information” means any
          written, verbal, visual, or digital information which will be or has
          already been disclosed to the Recipient, directly or indirectly,
          whatever the transmission means namely including any document,
          presentation, agreement, contract, offer, proposal, specification,
          design, drawing, invention, idea, trade secret, model (static, dynamic
          or financial), plan, study, analysis, report, concept, prototype,
          production schedule, data, know-how, sample, and budget; by the
          Discloser and/or for which the Recipient will have access to as part
          of the discussions and negotiations relating to the Projects and their
          evaluation. Such disclosure and sharing of Confidential Information
          shall be treated in accordance with the following terms and
          conditions:
        </p>
        <br />
        <p>
          The Recipient agrees to hold and retain any and all Confidential
          Information in the strictest confidence and secrecy and will not
          disclose or communicate, let disclose or let communicate, freely or
          against payment, any Confidential Information to any third party.
          Furthermore, the Receiver undertakes not to compete with, circumvent,
          avoid, by-pass or obviate (directly, indirectly or using third
          parties) Bay Capital Partners (or any of its affiliates) with respect
          to each of the Projects using the disclosures made under this
          Agreement or thanks to the Confidential Information; and not to use on
          its own behalf or for its own benefit (other than as part of the
          Projects) Confidential Information received from Bay Capital Partners
          (or any of its affiliates) in accordance with the provisions of this
          Agreement. In addition, the Receiver, hereby undertakes that it will
          not in any manner or form (directly, indirectly or using third
          parties) contact the other advisors, counsels, personnel, actual or
          potential partners, actual or potential investors and/or solicit nor
          accept or enter into any business or transaction arising out of,
          deriving from or in any way connected to the Projects without the
          express written permission of Bay Capital Partners.
        </p>
        <br />
        <p>
          The Recipient agrees that the Confidential Information will be used
          solely for the purposes of this Agreement. Measures taken by the
          Recipient to keep Confidential Information shall at least ensure the
          same level of protection applied by the Recipient for its own
          Confidential Information. The Recipient shall have the right to
          disclose or communicate Confidential Information to others other than
          him on a “need to know” basis but only subject to prior written
          authorisation from the Discloser.
        </p>
        <br />
        <p>
          2) NO ACQUISITION OF RIGHTS: Each Party acknowledges that Confidential
          Information supplied by the Discloser and any rights attached to such
          Confidential Information are and remain the Discloser's sole and
          exclusive property. No title, licence, copyright or any other right is
          granted or transferred to the Recipient, expressly or implicitly,
          under this Agreement and/or by the disclosure of Confidential
          Information to it, regardless whether or not covered by industrial or
          intellectual property rights.
        </p>
        <br />
        <p>
          3) EFFECTIVE DATE AND TERM: The effective date of this Agreement shall
          be the date of execution of this Agreement by both Parties (the
          “Effective Date”). The obligation of confidentiality and secrecy set
          out in this Agreement shall remain in full force and effect for a
          period of two (2) years from the Effective Date. The covenants and
          undertakings contained in this section shall survive even in case of
          early termination of this Agreement whatever its cause may be.
        </p>
        <br />
        <p>
          4) RETURN OF INFORMATION: Upon termination or expiry of this
          Agreement, whatever its reason; and/or on first request of the
          Discloser, the Recipient shall either return or destroy, at the
          Discloser's option, without delay, Confidential Information (including
          any copies or reproductions thereof) and all memoranda, notes,
          reports, and the like containing, embodying or referring to the
          Confidential Information.
        </p>
        <br />
        <p>
          5) PLACE OF JURISDICTION - APPLICABLE LAW: All disputes arising under
          this Agreement shall be exclusively submitted to the Courts of London
          (England) which shall settle the litigation exclusively in accordance
          with the laws of England and Wales.
        </p>
        <br />
        <p>
          6) NO THIRD PARTY RIGHTS: The provisions of this Agreement are
          intended to bind the Parties as to each other and are not intended to
          and do not create rights in any other person or confer upon any other
          person any benefits, rights or remedies, and no person is or is
          intended to be a third party beneficiary of any of the provisions of
          this Agreement.
        </p>
        <br />
        <p>
          7) DISCLAIMER: The documents in this data room (“documents'') are
          confidential and any reproduction, in whole or in part, is prohibited.
          It should not be transmitted to any person other than the original
          addressee(s) without the prior consent of Bay Capital Partners. The
          documents contain summary information about Bay Capital Partners and
          its present or future activities which is current as at the date of
          this non-disclosure agreement. The documents are not an offer or an
          invitation to acquire shares, bonds or any other financial products
          and is not a prospectus, product disclosure statement or other
          offering document under English law or any other law. The documents
          are for information purposes only and are not an invitation or offer
          of securities for subscription, purchase or sale in any jurisdiction.
          The documents may not be released or distributed in the United States.
          The documents do not constitute an offer to sell, or a solicitation of
          an offer to buy, any securities in the United States or in any other
          jurisdiction in which such an offer would be illegal. NO INFORMATION
          CONTAINED IN THE DOCUMENTS OR ANY OTHER WRITTEN OR ORAL COMMUNICATIONS
          TRANSMITTED OR MADE AVAILABLE TO A RECIPIENT OF THESE DOCUMENTS IS, OR
          SHALL BE RELIED UPON AS A PROMISE OR REPRESENTATION, WHETHER AS TO THE
          PAST OR FUTURE, AND NO LIABILITY WILL ATTACH. IN ADDITION, ANY
          PROJECTIONS AND ESTIMATES CONTAINED IN THE DOCUMENTS INVOLVE NUMEROUS
          AND SIGNIFICANT SUBJECTIVE DETERMINATIONS. ACCORDINGLY, NO
          REPRESENTATION OR WARRANTY CAN BE OR IS MADE AS TO THE ACCURACY OR
          ATTAINABILITY OF SUCH ESTIMATES AND PROJECTIONS. THE DOCUMENTS CONTAIN
          A FAIR SUMMARY OF Bay Capital Partners’S VISION. FURTHER INFORMATION
          WILL BE PROVIDED AT A LATER STAGE IF Bay Capital Partners DECIDES TO
          LAUNCH THE FUND.
        </p>
        <br />
        <p>
          This Agreement has been duly executed and delivered by the Parties on
          the date this Non-Disclosure Agreement is accepted by clicking on
          ‘Agree and Continue’ below.
        </p>
        <br /> <br />
        <div className="dis_btn">
          <Link to="/show" onClick={handleAgreeClick}>Agree</Link>
          <Link to="/login">Don't Agrre</Link>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default Disclaimer;
