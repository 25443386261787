import React from "react";
import "../Components/TermsConditions.css";
import MainFooter from "./MainFooter";

const TermsConditions = () => {
  return (
    <>
      <div className="team">
        <h1>TERMS & CONDITIONS OF USE</h1>
        <br />
        <p>
          PLEASE READ THESE TERMS & CONDITIONS OF USE (THE “TERMS”) CAREFULLY
          BEFORE ENTERING THIS WEBSITE (THE “WEBSITE”) OF Bay Capital Partners CAPITAL
          PARTNERS, L.P. EACH TIME YOU USE THIS WEBSITE, YOU SIGNIFY YOUR
          CONSENT TO THESE TERMS WITHOUT LIMITATION OR QUALIFICATION. THESE
          TERMS MAY BE MODIFIED AT ANY TIME WITHOUT NOTICE TO YOU BY POSTING
          REVISED VERSIONS OF THE TERMS ON THIS WEBSITE, WHICH WILL IMMEDIATELY
          BECOME EFFECTIVE. BY CONTINUING TO USE THIS WEBSITE, YOU CONSENT TO
          ANY SUCH CHANGES. IF YOU DO NOT AGREE TO THE TERMS, DO NOT USE THIS
          WEBSITE.
        </p>
        <br />
        <p>
          “Bay Capital Partners Capital Partners” or “Bay Capital Partners” is a trade name for
          certain services provided by Bay Capital Partners Capital Partners, L.P. (“Bay Capital
          Partners”, “we”, “us” or “our”) and its subsidiaries and affiliates
          (each such subsidiary and affiliate, a third-party beneficiary
          hereunder).
        </p>
        <br /> <br />
        <h2>
          {" "}
          <span>02</span> No Offer
        </h2>
        <p>
          The information, tools and material presented in this Website are
          provided to you for informational purposes only and are not intended
          to be, and should not be used or considered as an offer,
          recommendation or a solicitation to sell or an offer, recommendation
          or solicitation to buy or subscribe to any financial instruments,
          investment management services or advisory services. Further, the
          information contained in this Website does not constitute a commitment
          to lend money, underwrite any proposed transaction, purchase
          securities or other assets, provide or arrange financing, or provide
          any other services. It is the user’s responsibility to evaluate the
          accuracy, completeness and usefulness of any content provided on this
          Website. Your use of the Website constitutes your acknowledgement that
          you bear all risks associated with using such content.
        </p>
        <br />
        <p>
          This Website does not intend to provide investment, tax or legal
          advice and Bay Capital Partner LLC has rendered no advice concerning the
          legal, regulatory or accounting treatment, or possible tax
          consequences of any transaction described herein. Bay Capital Partners LLC
          does not represent that the securities, financial instruments,
          products or services are suitable or appropriate for all investors or
          customers. Decisions based on information contained on this Website
          are the sole responsibility of the user. Prior to the execution of any
          transaction involving information provided by this Website, the user
          should consult its business advisor, attorney, and/or tax and
          accounting advisors with respect to the price, suitability, value or
          other aspects of any stock, bond, mutual fund, security or other
          investment. Depending on the user’s specific investment objectives and
          financial position, the investments discussed on this Website may or
          may not be suitable. It is up to the user to weigh any investment
          decision carefully.
        </p>
        <br /> <br />
        <h2>
          <span>03</span> Content Disclaimers
        </h2>
        <p>
          We make reasonable efforts to ensure accuracy with respect to the
          Content (as defined below), but at times we may not promptly update or
          correct the Website even if we are aware that it is inaccurate,
          outdated or otherwise inappropriate. Dated information speaks only as
          of the date indicated, and not as of the date that you may access the
          information. Bay Capital Partners LLC does not warrant or guaranty the
          accuracy or completeness of the information made available on the
          Website. In addition, Bay Capital Partners LLC reserves the right, in its sole
          discretion, without any obligation and without any notice, to change,
          modify or delete the information, materials and descriptions on this
          Website and to suspend or deny access to it. Bay Capital Partners LLC may
          discontinue or change any product or service described on this Website
          at any time.
        </p>
        <br />
        <p>
          All data included in the Website is as of June 30, 2022, unless
          otherwise indicated. Amounts identified in the Website as “invested
          since inception” or “lent since inception” refer to investments
          originated (or “committed”) on behalf of our investors, co-investment
          partners, and joint ventures. Committed investments may include both
          funded and unfunded commitments relative to certain investments.
          Amounts identified in the Website as the “investment portfolio” refer
          to current committed investments across our investor and joint venture
          portfolios.
        </p>
        <br />
        <p>
          Past performance should not be taken as an indication or guarantee of
          future performance, and no representation or warranty, express or
          implied, is made regarding future performance. Opinions and/or
          estimates reflect a judgment at the original date of publication by
          Bay Capital Partners LLC and are subject to change without notice. The price,
          value of and income from any of the financial instruments mentioned on
          this Website may change over time.
        </p>
        <br />
        <p>
          This Website may contain, or may be deemed to contain,
          “forward-looking” statements, which are statements other than
          statements of historical facts. By their nature, forward-looking
          statements involve risks and uncertainties because they relate to
          events and depend on circumstances that may or may not occur. Future
          projections are subject to numerous risk factors and, accordingly, no
          forward-looking statements contained herein should be deemed, in any
          manner whatsoever, to be promises of future performance.
        </p>
        <br />
        <h2>
          <span>04</span> Third-Party Information and Sites
        </h2>
        <p>
          Bay Capital Partners LLC does not guarantee the accuracy or completeness of
          any information or data from third-party sources. The Website may also
          contain links to sites owned and operated by other parties. Inclusion
          of these links and services on the Website does not constitute our
          endorsement of the materials on those third-party sites and does not
          signify any affiliation with the owners or operators of those
          third-party sites and services. Your use of such third-party sites and
          services is subject to the terms and conditions set by the third-party
          site or service in question. Bay Capital Partners LLC is not responsible for
          and has no control over the privacy policies of those sites. We
          encourage you to review the privacy policies of those sites prior to
          providing them with any information.
        </p>
        <br />
        <h2>
          <span>05</span> Accessing the Website
        </h2>
        <p>
          If you have been provided with a username and password in order to
          access certain services and sections of the Website, you are
          responsible for maintaining the confidentiality of your username and
          password, and you agree to accept responsibility for all activities
          that occur under your username or password. Bay Capital Partners LLC will not
          be responsible or liable for any loss, expense or damage arising in
          any way from the use of the username and password or the accessing of
          information or materials therewith. You agree to notify Bay Capital Partners
          LLC immediately of any loss, theft, disclosure or unauthorized use of
          your password or any other breach of security.
        </p>
        <br />
        <h2>
          <span>06</span> Conduct on our Website
        </h2>
        <p>
          Bay Capital Partners LLC does not want to receive, and you are deemed to
          covenant and agree through the use of the Website not to provide, any
          information or materials to Bay Capital Partners LLC that are defamatory,
          threatening, obscene, harassing, in violation of any law, governmental
          requirements or otherwise unlawful, or that incorporate the
          proprietary information or materials of another person or entity.
        </p>
        <br />
        <p>
          While accessing or using any portions of the Website, you agree that
          neither you nor your agents shall do any of the following, including,
          without limitation, violating or attempting to violate the security of
          the Website:
        </p>
        <br />
        <div className="dot">
          <span>◉ tamper with any portion of the Website;</span>
          <span>
            ◉ impersonate any person or entity or misrepresent your affiliation
            with any other person or entity;
          </span>
          <span>◉ conduct fraudulent activities on the Website;</span>
          <span>
            ◉ obtain or attempt to gain unauthorized access to other computer
            systems, materials, information or any services available on or
            through the Website through any means, including through the use of
            meta tags or other hidden text or by other means not intentionally
            made publicly available or provided for through the Website;
          </span>
          <span>
            ◉ engage in “spidering,” “screen scraping,” “database scraping,”
            mining or harvesting of e-mail addresses or other personal
            information, or any other automatic or unauthorized means of
            accessing, logging-in or registering on any of the Website, or
            obtaining lists of users or other information from or through the
            Website, including, without limitation, any information residing on
            any server or database connected to the Website;
          </span>
          <span>
            ◉ interrupt, damage, disable, overburden or impair the Website or
            interfere with any other party’s use and enjoyment of the Website,
            such as sending mass unsolicited messages or “flooding” servers with
            requests;
          </span>
          <span>
            ◉ circumvent, reverse engineer, decrypt, or otherwise alter or
            interfere with (or attempt, encourage or support anyone else’s
            attempt to do any of the foregoing) the Website or its services or
            any software on the Website;
          </span>
          <span>
            ◉ attempt to probe, scan, or test the vulnerability of the Website
            or to breach the security or authentication measures without proper
            authorization;
          </span>
          <span>
            ◉ violate, plagiarize or infringe the rights of third parties,
            including without limitation, copyright, trademark, trade secret,
            confidentiality, contract, patent, rights of privacy or rights of
            publicity or any other proprietary or legal right; or
          </span>
          <span>
            ◉ remove any notices, warnings, labels, annotations, or instructions
            from any portion of the Website, including, without limitation, any
            patent, trademark, copyright, or other proprietary notices or
            license provisions.
          </span>
        </div>
        <br />
        <br />
        <p>
          Any violations of these Terms, this Website, system, or network
          security, including attempts to intentionally access a computer
          without authorization or exceed your authorized access level, may
          result in civil and criminal charges. Bay Capital Partners LLC may investigate
          occurrences that might involve such violations and may involve, and
          cooperate with, law enforcement authorities in prosecuting users who
          are involved in such violations. Bay Capital Partners LLC may, without prior
          notice or warning of any kind, restrict or terminate the access of any
          and all users at any time and for any reason, to all or any portion of
          the Website, including if, among other things, Bay Capital Partners LLC
          concludes in its sole discretion that such restriction or termination
          is necessary to prevent, or prevent the further spread of, a virus,
          security breach, or system malfunction.
        </p>
        <br /> <br />
        <h2>
          <span>07</span> Ownership of Content
        </h2>
        <p>
          All text, images, graphics, sounds, software code and other content
          (“Content”) on the Website, including the selection and arrangement
          thereof, are protected under the copyright laws, trademark laws and
          other intellectual property laws of the United States and other
          countries and are the property of Bay Capital Partners LLC or its affiliates
          or applicable third parties, unless otherwise stated. They may only be
          used for the personal education of visitors to the Website and they
          may not be placed in the public domain. You may not reproduce,
          redistribute, publish or otherwise use by electronic or any other
          means any of the Content without Bay Capital Partners’s LLC prior written
          consent.
        </p>
        <br /> <br />
        <h2>
          <span>08</span> Trademarks
        </h2>
        <p>
          Bay Capital Partners’s LLC name and logos on the Website are a service mark of
          Bay Capital Partners LLC Capital Partners, L.P. They may not be used or
          displayed without the prior written consent of Bay Capital Partners LLC. All
          other trademarks, service marks and company names and logos appearing
          on the Website are the property of their respective owners and their
          use shall not be construed as an endorsement of Bay Capital Partners of any
          kind.
        </p>
        <br /> <br />
        <h2>
          <span>09</span> Visitors from Outside the United States
        </h2>
        <p>
          The Website and services are offered from the United States. We make
          no representations or warranties that the Website or services are
          appropriate or available for use in countries outside of the United
          States. Visitors who choose to access the Website and services from
          outside of the United States do so at their own initiative and are
          responsible for compliance with any and all local laws and regulations
          that may apply to such access and use. If you use the Website outside
          of the United States, you consent to having your personal data
          transferred to and processed in the United States. Access to the
          Website and the services is prohibited where such access or use
          violates applicable laws or regulations.
        </p>
      </div>
              <MainFooter/>
    </>
  );
};

export default TermsConditions;
