import React from 'react';
import "../Pages/Fundmodel.css";
import pdfFile from "../Imges/fund.pdf";

const Fundmodel = () => {
  return (
    <>
     <iframe id='myPDF' src={pdfFile} frameborder="0"></iframe>
    </>
  )
}

export default Fundmodel