import React from 'react';
import "../Components/Footer.css";
import { Link } from 'react-router-dom';



const Footer = () => {
    return (
        <>
           <div className="bottom">
          <div className="empty"></div>
          <div className="start">
            <Link to="/about">Start From Here</Link>
          </div>
        </div>
        </>
    )
}

export default Footer