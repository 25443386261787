import React from "react";
import "../Pages/Team.css";
import team1 from "../Imges/team1.png";
import team3 from "../Imges/team3.jpeg";
import tem2 from "../Imges/Richard.png";
import Gregory  from "../Imges/Gregory.pdf";
import Richard from "../Imges/Richard Meringolo Final.pdf";
import Mary from "../Imges/Mary.pdf";
import Navbar from "../Components/Navbar";
import MainFooter from "../Components/MainFooter";

const Team = () => {
  const teamData = [
    {
      id: 1,
      userName: "Gregory F. Mulligan",
      dis: "Managing Partner and Chief Invesmtent Officer.",
      userImg: team1,
      userPdf: Gregory 
    },
    {
      id: 2,
      userName: "Richard A. Meringolo",
      dis: "Partner and Chief Credit Officer",
      userImg: tem2,
      userPdf: Richard
    },
    {
      id: 3,
      userName: "Mary Halvey",
      dis: "Chief Financial Officer",
      userImg: team3,
      userPdf: Mary
    },
  ];

  return (
    <>
      <div className="team_section">
        <Navbar />

        <div className="team_container">
          <div className="team_headibg">
            <h1>
              A team of dedicated professionals committed to <br />
              exceeding your expectations
            </h1>
          </div>

          <div className="team_continer">
            {teamData.map((data) => (
              <>
                <div className="box_card">
                  <a href={data.userPdf} rel="noreferrer" target="_blank">
                    <img src={data.userImg} alt="logo" />
                  </a>
                  <div className="box_body">
                    <a href={data.userPdf} rel="noreferrer" target="_blank">
                      <h2>{data.userName}</h2>
                      <p>{data.dis}</p>
                    </a>
                  </div>
                </div>
              </>
            ))}



          </div>
        </div>
        <MainFooter />
      </div>
    </>
  );
};

export default Team;
