import React from "react";
import "../Pages/Home.css";
// import { motion } from "framer-motion";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const Home = () => {
  return (
    <>
      <div className="home_section">
        <Navbar />
        <div className="home">
          <div className="home_line"></div>
          <div className="home_text">
            <h1>
              All Good Ideas <br /> Start With a Bay
              <br /> Capital.
            </h1>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
