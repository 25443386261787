import React from "react";
import "../Pages/Transactiondescription.css";
import { useEffect } from "react";

const Transactiondescription = () => {
  useEffect(() => {
    if (window.location.hash === "#dover") {
      const element = document.getElementById("dover");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#abt") {
      const abt = document.getElementById("abt");
      if (abt) {
        abt.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#ids") {
      const ids = document.getElementById("ids");
      if (ids) {
        ids.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#hourprint") {
      const hourprint = document.getElementById("hourprint");
      if (hourprint) {
        hourprint.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#sequest") {
      const sequest = document.getElementById("sequest");
      if (sequest) {
        sequest.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#tetexdt") {
      const tetexdt = document.getElementById("tetexdt");
      if (tetexdt) {
        tetexdt.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#little") {
      const little = document.getElementById("tetexdt");
      if (little) {
        little.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#access") {
      const access = document.getElementById("access");
      if (access) {
        access.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#tel") {
      const tel = document.getElementById("tel");
      if (tel) {
        tel.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#control") {
      const control = document.getElementById("control");
      if (control) {
        control.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#roadrunner") {
      const roadrunner = document.getElementById("roadrunner");
      if (roadrunner) {
        roadrunner.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#claris") {
      const claris = document.getElementById("claris");
      if (claris) {
        claris.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#glendale") {
      const glendale = document.getElementById("glendale");
      if (glendale) {
        glendale.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (window.location.hash === "#xand") {
      const xand = document.getElementById("xand");
      if (xand) {
        xand.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <div className="tetexdt">
        <div id="abt">
          <h2>AB&T</h2>
          <p>
            AB&T was a broad band and Internet services company offering
            connectivity and a full-suite of local and long distance telephone
            services in 14 communities in northwest Ohio and southeast Michigan.
            The company planned to enter significant additional markets which it
            may cover in its network contiguous to its existing communities. The
            company provided service to both residential and small business
            customers with the majority of its lines at loan inception serving
            residential customers. The company plan was to continue to grow its
            residential base and significantly grow its small business customer
            base. Bay Capital’s debt investment was in support of the planned
            growth. <br /> Bay Capital provided $2million in term debt, secured
            by a first lien on all assets and having a 5-year bullet maturity.
            Pricing was offered at a fixed rate of 12% with attached warrants
            representing approximately 20% ownership on a fully diluted basis.
          </p>
        </div>

        <br />

        <div id="dover">
          <h2>Dover Saddlery</h2>
          <p>
            Dover was founded in 1975 by Jim and David Powers who were top
            ranked English riding champions on the U.S. Equestrian team. Jim
            Powers was also a member of the 1972 U.S. Olympic equestrian team.
            The brothers aimed to bring their unique understanding of higher
            level equestrian competitive needs to better serve the industry’s
            customers. As a result of their focus on quality and premium
            positioning, Dover Saddlery has been a premier upscale brand in the
            English riding industry for over 30 years. The Powers opened its
            Wellesley, MA retail store in 1975 and began catalog operations in
            1982.
            <br />
            Bay Capital provided $2 million of a $5 million senior loan
            facility, which was used by Stephen Day to purchase the company. Bay
            led the financing and syndicated the remaining $3 million to two
            other northeast based SBIC’s. The $5 million in term debt was priced
            at 14% with warrants attached equaling 3% fully diluted ownership
            for Bay. The term debt was secured by a first lien on all assets.
          </p>
        </div>

        <br />

        <div id="ids">
          <h2>IDS/Quantem Aviation</h2>
          <p>
            Founded in 2002 by two retired United Airlines ramp workers at
            Colorado Springs Airport, to provide airplane de-icing services to
            commercial airlines IDS operated at four locations: at the time of
            Bay’s investment. Buffalo, NY (BUF); Colorado Springs, CO (COS);
            Detroit, MI (DTW); and Pittsburgh, PA (PIT). IDS had the requisite
            permits to operate at these airports and existing contracts with a
            number of carriers using these locations, including USAir Ways,
            Skywest United Express, Southwest Airlines, Northwest (Pinnacle)
            Airlines, Trans States USAir, and Piedmont Airlines. The Company's
            largest operations are Detroit, where IDS operates 15 deicing
            trucks, while its corporate headquarters is in Pittsburgh, where the
            Company operates four deicing <br /> trucks and twelve stationary
            airport deicing booms. IDS also operated five deicing trucks in
            Buffalo and four in Colorado Springs. Bay Capital invested $2
            million of an $8.5 million financing round which Bay Capital led and
            syndicated to two other SBIC’s. The capital was used to assist Sal
            Calvino, an experienced aviation industry veteran, to purchase the
            company. The financing was in the form of 5 -year term debt, with a
            bullet maturity. The facility was priced at 12% and had detachable
            warrants with ownership equal to 4.6% for Bay Capital’s piece of the
            loan.
          </p>
        </div>

        <div id="hourprint">
          <h2>48HourPrint.coom</h2>
          <p>
            Advanced Media d/b/a 48HourPrint.com was organized in 2001-02 and
            witnessed significant growth over the following 5 years. The Company
            is in the commercial online web-to-print segment of the commercial
            printing business. 48Hour offered a wide array of printed products.
            These products include: business cards, brochures, pocket folders,
            postcards, booklets and other marketing / advertising materials,
            along with adjunct services such as mail fulfillment. The latter was
            introduced at the Company in 2005-06. <br /> This niche’s customers
            are primarily small to medium size businesses, though larger
            companies like 48HourPrint have clients in the Fortune 500.
            Likewise, customers can be local in nature or nationwide dependent
            upon the size of the company and its capabilities. The growth of the
            internet, along with intermediaries such as UPS and Federal Express,
            allow for immediate order fulfillment (order-taking and
            confirmation) followed by next day or 2-day delivery of the final
            printed product. <br /> Bay Capital provided $2 mm of a $3 mm 5-year
            amortizing facility, secured by a first lien on all assets. SEED
            Capital of Massachusetts introduced the opportunity to Bay and
            provided $1 mm. The proceeds of the loan was used to purchase the
            interests from one of the founders who is no longer active in the
            business.{" "}
          </p>
        </div>
        <br />

        <div id="sequest">
          <h2>Sequest Technology</h2>
          <p>
            Sequest Technologies, Inc. is a software company headquartered in
            Lisle, Illinois. In 1983, the Company was started by Leonard Brink
            as a consulting company to the Behavioral Healthcare industry.
            Leonard Brink was the Chairman and CEO of Sequest since it was
            incorporated in 1983 through 2005. From 1978 to 1983, Leonard was
            Associate Director with the Joint Commission on Accreditation of
            Healthcare Organizations (JCAHO) and remains an active surveyor with
            the JCAHO Behavioral Healthcare Accreditation Services Program. The
            experience he gained working with the Behavioral and Substance Abuse
            industries enabled him to gain the expertise to transform his
            company from a consulting firm into a product company in 1992.
            <br /> <br /> The Health Insurance Portability and Accountability
            Act of 1996 (“HIPAA”) significantly changed the market opportunity
            for Sequest. The Administrative Simplification <br /> <br />{" "}
            provisions of the Health Insurance Portability and Accountability
            Act of 1996 (HIPAA, Title II) required the Department of Health and
            Human Services (HHS) to establish national standards for electronic
            health care transactions as well as national identifiers for
            providers, health plans, and employers. It also addressed the
            increasing need for the security and privacy of health-related data.
            As the industry adopts these standards the efficiency and
            effectiveness of the nation's health care system should improve the
            use of electronic data interchange. Given this evolution in HIPPA
            requirements, Sequest set out to create a software solution for the
            mental and behavioral health space based on the Company’s extensive
            clinical background. <br /> <br /> Bay was introduced to Jason Woody
            at Palm Ventures, LLC by the Greenwich-based Brookside Group. Bay
            worked with Palm Ventures (“Palm”) to structure a $2 million
            mezzanine facility in support of Palm’s $4.7 million buyout of
            Sequest Technologies, Inc. (“Sequest”). Sequest is a software
            company providing Electronic Medical Records (“EMR”) software and
            services to the mental health, behavioral health and human services
            sector. <br /> <br /> Palm used $2.0 million of its capital plus the
            proceeds of the bay financing to purchase all of Sequest
            Technologies’ common shares from the retiring company founders,
            Leonard and Diane Brink, and to fund a significant working capital
            balance. Palm acquired a controlling interest in Sequest and
            partnered with Seven Hills Foundation, a leading non-profit provider
            of mental and behavioral health services in the state of
            Massachusetts. Seven Hills was not only be a minority investor in
            Sequest but implemented the Sequest software throughout its clinics
            and other medical locations. <br /> <br /> Bay’s $2mm facility was
            structured as a 5-year bullet maturity, secured by a first lien on
            all assets. The facility was priced at 14% with warrants attached to
            purchase 15% of the company’s common stock.
          </p>
        </div>

        <br />

        <div id="tetexdt">
          <h2>Tetexdt</h2>
          <p>
            At the time of Bay Capital’s loan to The Ladders it was the #1
            source for $100k+ jobs and in November 2006 officially became the
            largest specialty employment website. The service had over 180,000
            paid subscribers and over 2 million users. The company had grown its
            membership base by over 100% year-over-year since its inception. The
            company posted over 80,000 new jobs per week on its eight specialty
            sites—SalesLadder, HRLadder, LawLadder, TechnologyLadder,
            FinanceLadder, MktgLadder, OpsLadder, and UpLadder. The company had
            achieved success in establishing its brand by focusing on its target
            market of $100k+ talent and by offering superior services to its
            users. The company built significant brand recognition over the
            years through aggressive marketing efforts. <br /> Bay Capital
            invested $2 million of a $5 million mezzanine facility. Bay was
            introduced to the opportunity by Tom Matlack an LP in the Bay’s
            mezzanine Fund. The capital was used to fuel growth. The loan was
            priced at 14%, with warrants for approximately 2% of the company and
            was secured by a second lien on all assets, behind Lighthouse
            Financial which provided senior financing.
          </p>
        </div>

        <br />

        <div id="little">
          <h2>Little Blue Insite (“LBI”)</h2>
          <p>
            The Little Blue Book (“LLB”) was founded by Dr. Jerold Spitz and his
            wife Lauren in 1988 out of frustration for the lack of quality and
            easy-to-access contact information for physicians when providing
            patient referrals. WebMD purchased Little Blue Book from the Spitz’s
            in 2003 and Lauren had maintained a leadership role at the Company
            since her husband’s death in 2004. Since its beginnings, LBB evolved
            into one of the nation’s leading providers of up-to-date physician
            contact information through its physician information directories.
            The Company had a unique and longstanding, mutually beneficial
            relationship with physicians that was developed over more than 20
            years. This relationship provided the Company with a competitive
            advantage because it posed significant barriers of entry into the
            market. Doctors recognized The Little Blue Book publications as
            trusted, comprehensive, and up-to-date sources for contact
            information on physicians in the industry. The Little Blue Book
            publications contained data on over 400,000 active physicians
            nationwide including contact information, insurance information, and
            each doctor’s specialty. Little Blue Book offers three
            publications—Little Blue Book, Little Yellow Book, and Physician NPI
            Directory. <br /> <br />
            Bay Capital provided a $2 million of a $4.5 million facility to help
            fund the purchase of LBI from WebMD by two entrepreneurs. LLB was
            something of an orphan asset and undermanaged by WebMD. Funds also
            were used to fund investment to create a digital platform.
            <br /> <br /> Bay Capital led the investment and brought in SEED
            Capital and Northeast Capital. The 5-year amortizing facility was
            priced at 14%, and also purchased a 46% stake in the company. The
            loan was secured by a first position in all assets.
          </p>
        </div>

        <br />

        <div id="access">
          <h2>Access Northeast</h2>
          <p>
            Access Northeast, founded in 2001, was a managed data services and
            co-location facilities provider. The Company provided data center
            space, WAN, disaster recovery and Internet services to enterprise
            customers. The managed services sector while competitive AXNE
            competed very effectively by offering high quality co-location
            space, flexibility in working with customers, strong knowledge of IT
            services, competitive pricing due to inherent cost advantages, and
            an entrepreneurial ability to move quickly. <br /> <br /> Bay led an
            $8.5 million facility that was used to fund significant planned
            CAPEX to help fuel the company’s very strong and profitable growth.
            Spring Capital and SEED Capital joined in the financing which was
            structured as a 5-year bullet maturity priced at 14% with warrants
            attached equal to ownership of 12.8%.
          </p>
        </div>

        <br />

        <div id="tel">
          <h2>Tel-Instrument</h2>
          <p>
            Tel-Instrument Electronics Corp. was based in Carlstadt, NJ and was
            founded in 1947. The Company provided avionic ramp and bench testers
            to both commercial and military customers for over 35 years. The
            Company’s historical design emphasis provided its customers with
            user-friendly, rugged, reliable, and affordable units. From its
            simple beginning, Tel evolved to become a leading designer and
            manufacturer of avionics test equipment and measurement solutions
            for the global commercial air transport, general aviation, and
            government/military aerospace and defense markets. <br /> <br />{" "}
            Tel’s instruments were used to test navigation and communications
            equipment installed in aircraft, both on the flight line (“ramp
            testers”) and in the maintenance shop (“bench testers”), and ranged
            in list price from $7,500 to $81,000 per unit. The Company continued
            to develop new products in anticipation of customers’ needs and to
            maintain its strong market position. From concept and design,
            through manufacturing, final assembly and quality inspection, Tel
            provides the latest technology with superior reliability,
            versatility and performance. Its development of multifunction
            testers has made it easier for customers to perform ramp tests with
            less operator training, fewer test sets, and lower product support
            costs. <br /> <br /> Tel-Instrument was ISO-9001:2000 quality
            certified, and compliant to ESD standard ANSI/ESD S20.20 and its
            products had received CE certification for sales into Europe. <br />{" "}
            <br /> The Company received $2.5 million to refinance its line of
            credit, purchase equipment and inventory, reduce its accounts
            payable liability, and for general working capital. BCA Mezzanine
            Fund, LP invested all $2.5 million of the transaction. The Note had
            a five year maturity and a fixed interest rate of 14%, payable
            monthly in arrears. The Note amortized monthly on a straight-line
            basis with a fixed monthly payment of principal and interest and
            warrants attached for 4.5% of the company. The facility was secured
            by a first lien on all assets. <br /> <br />
          </p>
        </div>

        <br />

        <div id="control">
          <h2>Control Cam</h2>
          <p>
            Control Cam, originally named Mar-Tech was formed as a Newco to
            merge a legacy business, which provided aerial services to cable
            television companies to detect electromagnetic leakage from cable
            systems as mandated by the Federal Communications Commission (FCC)
            and the Federal Aviation Administration (FAA) which mandated that
            cable TV systems have annual radiation leakage tests. <br /> Daryl
            Rosenberger, a commercial private pilot, founded Mar-Tech to provide
            an aerial service to survey cable systems electromagnetic leakage.
            He developed a system, involving both sensing devices and software,
            to determine and pinpoint leakages and generate informative
            geo-spatial maps of leakage locations and utilized a fleet of Cessna
            aircraft, authorized to fly at low altitudes to monitor leakage.
            Mar-Tech developed business with over 20 cable tv companies and
            developed a strong recurring revenue business as the FAA mandated
            leakage tests had to be conducted at least annually if not more.{" "}
            <br /> <br /> The other part of Control Cam was to develop an
            aerial-based imagery business by mounting high powered digital
            cameras in the fleet of existing Cessna’s. The size of the U.S.
            market for all forms of land imaging is several billion dollars
            annually. Within this market there are a variety of niche markets
            and Control Cam focused on, which were primarily the “parcel-based”
            sub-markets that include applications for mapping, tax assessors,
            utilities, construction, real estate development and marketing,
            insurance, and agriculture. The idea was to take aerial images in
            areas and cities where the Cessna’s were already programmed to fly
            cable tv survey runs. <br /> <br /> Bay Capital partnered with C3
            Capital of Kansa City to provide $7.5 million in financing to
            support the CAPEX associated with purchasing camera systems and
            outfitting the Cessna’s and working capital. $6.5million consisted
            of a 5-year senior term facility priced at 16% fixed rate and
            secured by a first lien on all assets. $1.5 million was in the form
            of preferred equity which owned 28% of the company on a fully
            diluted basis.
          </p>
        </div>

        <br />

        <div id="roadrunner">
          <h2>Roadrunner Restoration</h2>
          <p>
            Progressive Fire & Flood, Inc. doing business as Roadrunner
            Restoration (“Roadrunner” or the “Company”) is a provider of
            disaster mediation and restoration services primarily in the state
            of Texas. The business was founded by Rick Daniel in 2003, the
            current CEO and 100% owner of the Company. Headquartered in Houston,
            the Company developed a reputation for providing rapid and high
            quality disaster recovery services to its customers. <br /> <br />{" "}
            Roadrunner provides disaster recovery/mediation, restoration, and
            reconstruction service solutions for commercial, industrial and
            residential property owners affected by fires, floods, water damage,
            and other natural disasters. The Company restores properties, such
            as a home or building, and the contents of the properties after
            disaster. This helps homeowners and commercial property owners to
            rebuild after disaster strikes. Roadrunner’s services also helps
            insurance companies mitigate losses and avoid costly pay-outs
            associated with business interruption insurance. Management stated
            in diligence that through its involvement in the restoration
            process, Roadrunner effectively advocates for the property owner
            while satisfying the needs of its insurance company customers.{" "}
            <br /> <br />
            Bay was the sole investor in the transaction and was the only funded
            debt on the balance sheet at the closing of this financing. The Note
            will had a term of five years and a 14% interest rate with no
            principal repayments in the first two years, followed by 25% of the
            Note’s face amount due per annum in monthly payments in years three
            through five with a final bullet payment at maturity. In conjunction
            with the issuance of the Note, BCA was issued warrants with a strike
            price of $0.01 that represented an equity interest of 18% on a
            fully-diluted basis. The proceeds from the financing was be used to
            pay off two senior lines of credit, reduce current liabilities, and
            provide working capital for growth.
          </p>
        </div>

        <br />

        <div id="claris">
          <h2>Claris Vision</h2>
          <p>
            Claris Vision, formerly Koch Eye was the leading integrated eye care
            provider in the State of Rhode Island. The company provided a
            complete array of services to families, ranging from routine eye
            exams to glaucoma, retina, cataract and refractive procedures. In
            addition, the company developed a robust LASIK surgery specialty.
            Claris Vision also provided a full array of contacts and eye glass
            products. <br /> <br /> Bay invested $2 million and took its pro
            rata share of a total $8mm senior secured 5-year term loan, priced
            at 17% and $3.5 million in participating preferred stock, to assist
            an Independent Sponsor – Candescent Capital in the purchase of Koch
            Eye from the founder, Dr. Paul Koch and his siblings. BCA’s fully
            diluted ownership equaled approximately 2%. The transaction was led
            by Eagle Capital of St. Louis.
          </p>
        </div>

        <br />

        <div id="glendale">
          <h2>Glendale Parade</h2>
          <p>
            Catalog Marketplace, Inc., dba Glendale Parade is headquartered in
            Northvale, NJ and is a direct marketer of ceremonial uniform and
            parade accessories. Buyers of Glendale’s products include all
            branches of the military, ROTC/JROTC programs, police, fire and EMS
            departments, schools, fraternal organizations and government
            agencies. Glendale has served this niche market for over 60 years
            and has a reputation for top customer service and a broad product
            offering. <br /> <br /> Glendale carries approximately 16,000 SKU’s,
            allowing it to address the entire uniform and parade accessory
            marketplace. The Company sells a mix of proprietary products as well
            as other items that are bought from suppliers with whom Glendale has
            longstanding relationships. Glendale’s product offering includes
            flags, flag accessories, replica rifles, swords, and other related
            products that are common uniform accoutrements. The basic line has
            remained unchanged over the Company’s history. Glendale does not
            sell military collectibles or surplus items that can be found in
            Army-Navy retail locations. <br /> <br /> Bay invested $2 million,
            as part of a total $3.1 million investment led by Capital resource
            Partners of Boston. Bay’s investment consisted of a $1,750,000
            senior secured term loan priced at 16% with interest only through
            maturity and $250,000 in preferred stock resulting in an approximate
            10% ownership position.
          </p>
        </div>

        <br />

        <div id="xand">
        <h2>Xand Holdings</h2>
        <p>
          Please see Access Northeast. When Access Northeast was sold to Xand, a
          large aggregator of data center across the U.S. and majority owned by
          Abry Partners of Boston, Bay rolled $3mm of its gain into preferred
          stock of Xand, resulting in a 3% equity stake in the combined company.
        </p>
        </div>
      </div>
    </>
  );
};

export default Transactiondescription;
