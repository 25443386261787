import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDyOJ41a8gEUaYsGXYMtDW-nDaYdoTqeqc",
  authDomain: "myapp-ff766.firebaseapp.com",
  projectId: "myapp-ff766",
  storageBucket: "myapp-ff766.appspot.com",
  messagingSenderId: "266615745495",
  appId: "1:266615745495:web:ef5ff20804d4ad843ef992",
  measurementId: "G-WDKQZ8W7M1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;