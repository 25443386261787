import React from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import Navbar from "../../Components/Navbar";
import "../InvestorPDF/Show.css";
import pdf1 from "../../Imges/ESC Policy.pdf";
import pdf2 from "../../Imges/code of ethics & conflicts.pdf";
import pdf3 from "../../Imges/Greg Mulligan.pdf";
import pdf4 from "../../Imges/Due Diligence Questionnaire.pdf";
import pdf5 from "../../Imges/Fund Model.pdf";
import pdf6 from "../../Imges/Investment Management Agreement.pdf";
import pdf7 from "../../Imges/Mary Halvey.pdf";
import pdf8 from "../../Imges/LP Agreement.pdf";
import pdf9 from "../../Imges/Richard Meringolo Final.pdf";
import pdf10 from "../../Imges/PPM.pdf";
import pdf11 from "../../Imges/Subscription Booklet.pdf";
import pdf12 from '../../Imges/Whitepaper 1.pdf';
import pdf13 from '../../Imges/Whitepaper 2.pdf';
import pdf14 from '../../Imges/Bay Capital Investor Deck Final.pdf';
import pdf15 from '../../Imges/Whitepaper 3.pdf';
import pdf16 from '../../Imges/DDQ Annexure 1.pdf';
import pdf17 from '../../Imges/DDQ Annexure 2.pdf';
import pdf18 from "../../Imges/007 Bay Capital - Loan Tape.pdf";

const Show = () => {
  const { user, logOut } = useUserAuth();

  const handelLogout = async () => {
    try {
      await logOut();
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div className="show">
        <Navbar />
        <div className="showlog">
          <h1>
            Welcome <span>{user && user.email}</span>!
          </h1>
          <button onClick={handelLogout}>Log Out</button>
        </div>

        <div className="pdf">
          <h2>Bay Capital LLC</h2>
          <a href={pdf4} rel="noreferrer" target="_blank">•  Due Diligence Questionnaire</a>
          <a href={pdf16} rel="noreferrer" target="_blank">• Annexure 1</a>
          <a href={pdf17} rel="noreferrer" target="_blank">• Annexure 2</a>
          <a href={pdf14} rel="noreferrer" target="_blank">•  Investor Deck</a>
          <a href={pdf5} rel="noreferrer" target="_blank">•  Fund Model</a>
          <a href={pdf12} rel="noreferrer" target="_blank">•  Whitepaper 1</a>
          <a href={pdf13} rel="noreferrer" target="_blank">•  Whitepaper 2</a>
          <a href={pdf15} rel="noreferrer" target="_blank">•  Whitepaper 3</a>
          <a href={pdf18} rel="noreferrer" target="_blank">• Track Record</a>
          <a href={pdf2} rel="noreferrer" target="_blank" >•  Code of Ethics & Conflict of Interest Policy</a>
          <a href={pdf1} rel="noreferrer" target="_blank">• ESG Policy</a> 
          <h2>Executive Profiles</h2>
          <a href={pdf3} rel="noreferrer" target="_blank">•   Greg Mulligan</a>
          <a href={pdf9} rel="noreferrer" target="_blank">•  Richard Meringolo</a>
          <a href={pdf7} rel="noreferrer" target="_blank">•   Mary Halvey</a>
          <h2>Legal</h2>
          <span>New Fund Documents (Onshore)</span>
          <a href={pdf8} rel="noreferrer" target="_blank">•  LP Agreement</a>
          <a href={pdf10} rel="noreferrer" target="_blank">•  PPM</a>
          <a href={pdf11} rel="noreferrer" target="_blank">•  Subscription Booklet</a>
          <a href={pdf6} rel="noreferrer" target="_blank">•   Investment Management Agreement</a>
          <h2>Incorporation Documents (Offshore)</h2>
          <a href="#">•  Certificate of Incorporation</a>
          <a href="#">•  Certification of good standing</a>
          <a href="#">•  Regsitration Statement</a>
          <a href="#">•  LLC Agreement</a>
          <a href="#">•  Consent to act as a Manager</a>
          <a href="#">•  Operating Agreement</a>
          <h2>New Fund Documents (Offshore)</h2>
          <a href="#">•  LLC Agreement</a>
          <a href="#">•  PPM</a>
          <a href="#">• Subscription Booklet</a>
        </div>
      </div>
    </>
  );
};

export default Show;
