import React from "react";
import "../Pages/About.css";
import Navbar from "../Components/Navbar";
import MainFooter from "../Components/MainFooter";

const About = () => {
  const goToDown = () => {
    window.scrollTo({ top: 400, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="about_section">
        <Navbar />
        <div className="about">
          <div className="about_line"></div>
          <div className="about_text">
            <h1>
              Bay Capital is an alternative <br /> investment manager
            </h1>
          </div>
        </div>

        {/* this is scroll about page  */}

        <div className="about_container">
          <div className="btn">
            <button onClick={goToDown}>Learn More</button>
          </div>
          <br />
          <div className="one">
            <div className="one_about_text">
              <div className="one_heading">
                <h1>About Us</h1>
              </div>
              <div className="one_text">
                <p>
                  Bay Capital Investment Partners was formed in 2007 to address
                  the capital needs of companies in the lower middle market.
                  While our investment strategy is credit focused, we provide
                  senior and mezzanine financing, we also have the flexibility
                  to invest across the entire capital structure. Our principals
                  have decades of lending and investing experience.
                </p>
                <br /> <br />
                <p>
                  Bay Capital was founded to be a long-term capital partner to
                  the lower middle market. We support sponsors as well as
                  directly with management teams and we are active and
                  collaborative partners.
                </p>
                <br /> <br />
                <span>
                  Bay Capital Investment Partners has invested over $200 million
                  in over 40 companies since inception.
                </span>
                <br /> <br /> <br />
                <div className="square">
                  <div className="square_one">
                    <div className="zero">
                      <h1>$200 M+</h1>
                    </div>
                    <div className="zero_text">
                      <h2>
                      $200 million+ <br /> investment portfolio{" "}
                      </h2>
                    </div>
                  </div>
                  <br /> <br /> <br />
                  <div className="square_two">
                    <div className="hero">
                      <h1>
                        20+ <span>Years</span>
                      </h1>
                      <p>
                        Leadership team has an average of <br /> 20+ years of
                        middle market <br /> experience
                      </p>
                    </div>
                    <div className="hero_text">
                      {/* <h1>10+</h1>
                      <p>Employees</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    </>
  );
};

export default About;
