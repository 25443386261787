import React from 'react';
import "../Pages/Curenteinvestment.css";
import Navbar from '../Components/Navbar';
import img2 from "../Imges/2.png";
import img3 from "../Imges/3.png";
import img4 from "../Imges/4.png";
import img5 from "../Imges/5.png";
import img7 from "../Imges/7.png";
import img8 from "../Imges/8.jpg";
import img9 from "../Imges/9.jpg";
import img10 from "../Imges/10.png";
import { Link } from 'react-router-dom';
import MainFooter from '../Components/MainFooter';

const Curenteinvestment = () => {
  return (
    <>
        <Navbar />

        <div className="curenteinvestment">
            <div className="Cur_heading">
            <h2>Current Investment Portfolio</h2>
            </div>

            <div className="cur_box">

                <div className="box_2">        
                    <div className="box_text2">
                        <h2>Boutique Brands</h2>
                        <br />
                        <Link to="">DiscountDivas.com</Link>
                        <span>Huntsville, AL</span>
                        <br />
                        <Link to="https://www.fillyflair.com/">FillyFlair.com</Link>
                        <span>Sioux, SD</span>
                        <br />
                        <Link to="https://www.limelush.com/">LimeLush.com</Link>
                        <span>Orem, UT</span>
                        <br />
                        <p>Boutique Brands, LLC is a platform for fast fashion online women's apparel retailers catering to distinct customer segments. Current brands include Discount Divas, Filly Flair, and Lime Lush.</p>
                    </div>

                    <div className="box_img2">
                        
                        <img src={img2} alt="" />
                        
                    </div>
                </div>


                <div className="box_1">
                    <div className="box_img">
                       
                        <img src={img3} alt="" />
                        
                    </div>
                    <div className="box_text">
                        <h2>CityBridge</h2>
                        <br /> <br />
                        <Link to="https://www.link.nyc/">Link.NYC</Link>
                        <span>New York, NY</span>
                        <br /> <br />
                        <p>CityBridge, a consortium that includes CIVIQ Smartscapes, operates LinkNYC with the City of New York. LinkNYC is a communications network that will replace thousands of pay phones across the five boroughs with new structures called Links which will provide superfast, free public Wi-Fi, and other consumer services and digital advertising.</p>
                    </div>
                </div>


                <div className="box_2">        
                    <div className="box_text2">
                        <h2>Civiq Smartscapes</h2>
                        <br />
                        <Link to="http://www.civiqsmartscapes.com/">CiviqSmartScapes.com</Link>
                        <span>Milford, MA</span>
                        <br />
                        <p>CIVIQ Smartscapes designs and manufactures public-facing smart communications infrastructure with a range of services and capabilities – all paid for through media.</p>
                    </div>

                    <div className="box_img2">
                        
                        <img src={img4} alt="" />
                       
                    </div>
                </div>


                <div className="box_1">
                    <div className="box_img">
                        
                        <img src={img5} alt="" />
                       
                    </div>
                    <div className="box_text">
                        <h2>Denver Glass Interiors</h2>
                        <br /> <br />
                        <Link to="http://denverglassinteriors.com/">DenverGlassInteriors.com</Link>
                        <span>Englewood, CO</span>
                        <br /> <br />
                        <p>Denver Glass designs, fabricates, and installs glass products for both residential and commercial applications across the Front Range of Colorado.</p>
                    </div>
                </div>


                <div className="box_1">
                    <div className="box_img">
                       
                        <img src={img7} alt="" />
                       
                    </div>
                    <div className="box_text">
                        <h2>Secaucus, NJ</h2>
                        <br /> <br />
                        <Link to="http://www.mastercutlery.com/">MasterCutlery.com</Link>
                        <span>Englewood, CO</span>
                        <br /> <br />
                        <p>Master Cutlery is a designer, manufacturer, and distributor of cutlery in the hunting & fishing, military and tactical, collectibles, and martial arts market segments.</p>
                    </div>
                </div>

                <div className="box_2">        
                    <div className="box_text2">
                        <h2>SerenaGroup</h2>
                        <br />
                        <Link to="http://www.serenagroups.com/">SerenaGroups.com</Link>
                        <span>Cambridge, MA</span>
                        <br />
                       <p>SerenaGroup is a leading clinical research company operating hospital-based wound care and hyperbaric oxygen therapy centers across the US, providing novel treatment in all patient care settings.</p>
                    </div>

                    <div className="box_img2">
                       
                        <img src={img8} alt="" />
                        
                    </div>
                </div>


                <div className="box_1">
                    <div className="box_img">
                        
                        <img src={img9} alt="" />
                        
                    </div>
                    <div className="box_text">
                        <h2>TC Studios</h2>
                        <br /> <br />
                        <Link to="http://tcstudios.net/">TC Studios.com</Link>
                        <span>Lancaster, PA</span>
                        <br /> <br />
                        <p>TC Studios is a commercial photography, video and production services firm with full-service studio capability. It serves the advertising, marketing and public relations impressions requirements for substantive retailers, manufacturers and distributors of high-design consumer and home building products.</p>
                    </div>
                </div>


                <div className="box_2">        
                    <div className="box_text2">
                        <h2>Viking Restaurants</h2>
                        <br />
                        <Link to="http://redkingfoods.com/">RedKingFoods.com</Link>
                        <span>Minneapolis, MN</span>
                        <br />
                       <p>Viking Restaurant owns and operates 25 Burger King franchised Restaurants in the greater Minneapolis-St. Paul area of Minnesota.</p>
                    </div>

                    <div className="box_img2">
                        
                        <img src={img10} alt="" />
                        
                    </div>
                </div>


            </div>
        </div>
        <MainFooter/>
    </>
  )
};

export default Curenteinvestment