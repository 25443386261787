import React from "react";
import "../Components/Navbar.css";
import { Link } from "react-router-dom";
import logo from "../Imges/logo.png";
import { useState } from "react";
import DropDown from "./DropDown";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const changeClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  return (
    <>
      <nav className="navbar">
        <Link to="/" className="logo">
          <img src={logo} alt="" />
        </Link>

        <div className="menu-icon" onClick={changeClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>

        <ul className={click ? "nav-side-menu start" : "nav-side-menu"}>
          <li className="nav-items">
            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
              About
            </Link>
          </li>

          <li className="nav-items">
            <Link to="/investorLogin" className="nav-links" onClick={closeMobileMenu}>
            Investor Login
            </Link>
          </li>

          <li className="nav-items">
            <Link to="/team" className="nav-links" onClick={closeMobileMenu}>
            Team
            </Link>
          </li>

          <li className="nav-items">
            <Link to="/investment" className="nav-links" onClick={closeMobileMenu}>
            Investment Criteria
            </Link>
          </li>

          <li
            className="nav-items"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link className="nav-links" onClick={closeMobileMenu}>
              {" "}
              Portfolio
              {dropdown && <DropDown />}
            </Link>
          </li>
          
          <li className="nav-items">
            <Link to="/news" className="nav-links" onClick={closeMobileMenu}>
            News
            </Link>
          </li>

          <li className="nav-items">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              {" "}
              Contact{" "}
            </Link>
          </li>

          {/* <li className="nav-items">
            <Link
              to="/sign-up"
              className="nav-links-button"
              onClick={closeMobileMenu}
            >
              {" "}
              Sign Up{" "}
            </Link>
          </li> */}
        </ul>
      </nav>

      {/* <div className="Navbar">
        <span className="nav-logo">
          <Link to="/"><img src={logo} alt="" /></Link>
        </span>
        <div className={`nav-items ${isOpen && "open"}`}>
          <Link to="/about">About</Link>
          <Link to="/investorLogin"> Investor Login</Link>
          <Link to="/team">Team</Link>
          <Link to="/investment">Investment Criteria</Link>
          <Link to="protfilo">Protfilo</Link>
          <Link to="/news">News</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div
          className={`nav-toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div> */}
    </>
  );
};

export default Navbar;
