import React from "react";
import "../Components/Policy.css";
import MainFooter from "./MainFooter";

const Policy = () => {
  return (
    <>
      <div className="policy">
        <h1>PRIVACY POLICY</h1>
        <br />
        <span>1st Feb 2023</span>
        <br />
        <p>
          Bay Capital Partners Capital Corporation (the “Company”), Bay Capital
          Partners Capital Partners, L.P. (“Bay Capital Partners”), Bay Capital
          Partners LLC (“Bay Capital,” together with Bay Capital Partners, the
          “Advisers”) (collectively, “our,” “us” or “we”) are committed to
          protecting your privacy. Your privacy is important to us. This privacy
          policy (“Policy”) explains how we collect, use, disclose and otherwise
          process personal information, including when you: • visit our
          websites, associated sub-domains, and mobile applications (the
          “Sites”); • submit personal information to us; • authorize us to
          obtain personal information from other sources; and • interact with us
          (by email, telephone, online or in person).
        </p>
        <br /> <br />
        <h2>02What Kind of Information We Collect</h2>
        <br />
        <p>
          Based on your interaction(s) with us, we may collect personal
          information about you that falls within the below categories of data.
          These categories also represent the categories of personal information
          that we have collected over the past 12 months. Note that the
          categories listed below are defined by Washington state law. Inclusion
          of a category in the list below indicates only that, depending on the
          services and products we provide you, we may collect some information
          within that category. It does not necessarily mean that we collect all
          information listed in a particular category for all of our customers
          or applicants. We disclose the below categories of information with
          our partners and service providers.
        </p>
        <br /> <br />
        <div className="categorypurpose">
          <div className="category">
            <h1>Category</h1>
            <br />
            <p>
              Identifiers such as a real name, alias, postal address, unique
              personal identifier, online identifier Internet Protocol address,
              email address, account name, social security number, driver’s
              license number, passport number, or other similar identifiers.
            </p>
            <br />
            <p>
              Information that identifies, relates to, describes, or is capable
              of being associated with, a particular individual, including, but
              not limited to, your name, signature, social security number,
              physical characteristics or description, address, telephone
              number, passport number, driver’s license or state identification
              card number, insurance policy number, education, employment,
              employment history, bank account number, credit card number, debit
              card number, or any other financial information, medical
              information, or health insurance information.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              Characteristics of classes protected under federal or California
              law, including: familial status, disability, sex, national origin,
              religion, color, race, sexual orientation, gender identity and
              gender expression, marital status, veteran status, medical
              condition, ancestry, source of income, age, or genetic
              information.
            </p>
            <br />
            <p>
              Commercial information, including records of personal property,
              products or services purchased, obtained, or considered, or other
              purchasing or consuming histories or tendencies.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>
              Internet or other electronic network activity information,
              including, but not limited to, browsing history, search history,
              and information regarding a consumer’s interaction with an
              Internet Web site, application, or advertisement.
            </p>
            <br />
            <p>Geolocation data.</p>
            <br />

            <p>Professional or employment-related information.</p>
          </div>
          <div className="purpose">
            <h1>Purpose of processing</h1> <br />
            <p>
              A subset of this data is processed in connection with a number of
              our operational functions, including to set up, administer and
              manage your account or investment, respond to your requests and
              queries, facilitate the transfer of funds, facilitate any other
              transactions, and to provide you with account statements.
            </p>
            <br />
            <p>
              It is also necessary to comply with our legal and regulatory
              obligations, for example, to verify the identity and address of
              our investors, maintain statutory registers, comply with the U.S.
              Office of Foreign Assets Control list and other governmental
              sanction lists, to prevent and detect fraud, to maintain the
              integrity and security of our systems, to carry out audit checks
              and conduct surveillance and investigations, comply with lawful
              requests. It also may be used for marketing purposes, including
              offering you products that may interest you.
            </p>
            <br />
            <p>
              A subset of this data is necessary to open and manage an
              application for employment with the Adviser as well.
            </p>
            <br />
            <p>
              A subset of this data is processed in connection with a number of
              our operational functions, including to set up, administer and
              manage your account or investment, respond to your requests and
              queries, facilitate the transfer of funds, facilitate any other
              transactions, and to provide you with account statements.
            </p>{" "}
            <br />
            <p>
              It is also necessary to comply with our legal and regulatory
              obligations, for example, to verify the identity and address of
              our investors, maintain statutory registers, comply with the U.S.
              Office of Foreign Assets Control list and other governmental
              sanction lists, to prevent and detect fraud, to maintain the
              integrity and security of our systems, to carry out audit checks
              and conduct surveillance and investigations, comply with lawful
              requests. It also may be used for marketing purposes, including
              offering you products that may interest you.
            </p>
            <br />
            <p>
              A subset of this data is necessary to open and manage an
              application for employment as well.
            </p>{" "}
            <br />
            <p>
              A subset of this data is processed in connection with a number of
              our operational functions, including to set up, administer and
              manage your account or investment.
            </p>{" "}
            <br />
            <p>
              This information is collected and processed where required by law.
            </p>
            <br />
            <p>
              A subset of this data is processed in connection with a number of
              our operational functions, including to set up and administer your
              account or investment, and to respond to your requests and
              queries. It may also be used for marketing purposes, including
              offering you products that may interest you.
            </p>
            <br />
            <p>
              This data is processed in order to optimize performance of the
              Site and to offer you products and services.
            </p>{" "}
            <br />
            <p>
              It is also processed to detect security incidents, protect against
              malicious, deceptive, fraudulent or illegal activity, and for data
              analytics.
            </p>{" "}
            <br />
            <p>
              This data is processed in order to optimize performance of the
              Site and to offer you products and services. It is also processed
              to detect security incidents, protect against malicious,
              deceptive, fraudulent or illegal activity, and for data analytics.
              We do not, however, collect “precise geolocation” data as defined
              under California law.
            </p>{" "}
            <br />
            <p>
              A subset of this data is processed in connection with a number of
              our operational functions, including to set up, administer and
              manage your account or investment, respond to your requests and
              queries, facilitate the transfer of funds, facilitate any other
              transactions, and to provide you with account statements.
            </p>{" "}
            <br />
            <p>
              It is also necessary to comply with our legal and regulatory
              obligations, for example, to verify the identity and address of
              our investors, maintain statutory registers, comply with the U.S.
              Office of Foreign Assets Control list and other governmental
              sanction lists, to prevent and detect fraud, to maintain the
              integrity and security of our systems, to carry out audit checks
              and conduct surveillance and investigations, and to comply with
              lawful requests. It also may be used for marketing purposes,
              including offering you products that may interest you.
            </p>
          </div>
        </div>
        <br />
        <h2>03 How We Use this Information</h2>
        <br />
        <p>
          We use personal information we collect to provide our products and
          services and to meet our legal obligations, including to respond to
          your requests or correspondence, to contact you about our Sites, to
          request your input regarding ways in which we might improve Sites
          and/or to inform you about our activities, products and services or
          those of our affiliates. This use includes, to the extent permitted by
          law, sharing information among our employees, contractors, attorneys,
          administrators and other third party vendors. <br /> As further
          explanation to the above business purposes, we may use the personal
          information we collect for: <br /> • Our and our affiliate’s everyday
          business purposes: including operate our Sites, to process your
          transactions, maintain your accounts, and interact with you about your
          application, account or funding; to communicate with you about your
          access to and use of our Site; to respond to your inquiries; to
          provide troubleshooting; provide technical support; and for other
          customer service and support purposes <br /> • Analyzing and improving
          our business: including to better understand how users access and use
          our Sites, to evaluate and improve our Sites; to troubleshoot problems
          that may arise on the Sites; to increase our understanding of our
          customers; and for other research and analytical purposes. <br /> •
          Advertising, marketing and promotional purposes: to send you
          newsletters, offers or other information we think may interest you; to
          contact you about providing products, or other information we think
          may interest you; to identify potential new investors. <br /> •
          Securing and protecting our business: including to protect and secure
          our business operations, assets, investments in our portfolio
          companies, network and information and technology resources; to
          investigate, prevent, detect and take action regarding fraud,
          unauthorized access, situations involving potential threats to the
          rights or safety of any person or third party, or other unauthorized
          activities or misconduct. <br /> • Defending our legal rights:
          including to manage and respond to actual and potential legal disputes
          and claims, and to otherwise establish, defend or protect our rights
          or interests, including in the context of anticipated or actual
          litigation with third parties. <br /> • Auditing, reporting, corporate
          governance, and internal operations: including relating to financial,
          tax and accounting audits; audits and assessments of our operations,
          privacy, security and financial controls, risk, and compliance with
          legal obligations; our general business, accounting, record keeping
          and legal functions; and related to any actual or contemplated merger,
          acquisition, asset sale or transfer, financing, bankruptcy or
          restructuring of all or part of our business. <br /> • Complying with
          legal obligations: including to comply with the law, our legal
          obligations and legal process, such warrants, subpoenas, court orders,
          and regulatory or law enforcement requests. <br /> • Investor
          Servicing: servicing the Company’s and other clients of the Advisers,
          sending distributions, annual reports and other information about
          their investments, and sending proxy statements or other information
          required by law. Aggregate and De-identified Data. We may de-identify
          personal information and create anonymous and aggregated data sets and
          reports to assess, improve and develop our business, products and our
          financing to our customers, prepare benchmarking reports on our
          industry and for other research, marketing and analytics purposes.
          Such information may also be shared with third parties for other
          analytical purposes.
        </p>
        <br /> <br />
        <h2>04 Who Has Access to Personal Information</h2>
        <br />
        <p>
          All financial companies need to share customers’ personal information
          to run their everyday business. In this section, we list the reasons
          financial companies can share their customers’ personal information
          and the reasons that we choose to share. To the extent applicable,
          federal and state law gives you the right to limit certain types of
          sharing as described below in the Your Rights section. We may share
          the personal information we collect as follows: <br /> • Authorized
          Employees and affiliates: We may share your personal information with
          our authorized employees and affiliates; they may use such personal
          information for the purposes set out in this Privacy Policy.
          Affiliates refer to companies related by common ownership or control.
          They can be financial and nonfinancial companies. Our affiliates
          include companies with “Bay Capital Partners” in the name; financial
          companies such as Bay Capital Partners LLC Capital Corporation;
          nonfinancial companies, such as Bay Capital Partners LLC and others,
          such as Bay Capital. <br /> • Service providers: We may disclose
          personal information with third-party service providers who use this
          information to perform services for us, such as auditors,
          administrators, and consultants. We have agreements in place with
          service providers requiring that they only use personal information
          for designated purposes and that they only process information in
          accordance with this Privacy Policy. <br /> • Legal compliance: We may
          share personal information where necessary to comply with a law, legal
          obligation or legal process, such as in response to a valid court
          order, subpoena, or government investigation. In addition, we may
          share certain personal information in order to protect the rights,
          property and safety of the Company, Advisers and/or others, in support
          of our compliance with tax or corporate governance legislation, to
          assist law enforcement, for debt collection purposes, for loss and
          fraud prevention, or to regulatory or other governmental authorities
          with jurisdiction over us or our affiliates. <br /> • Business
          transfers: We may disclose and/or transfer personal information as
          part of any actual or contemplated merger, sale, transfer of assets,
          acquisition, financing and/or restructuring of all or part of our
          business, bankruptcy or similar event, including related to due
          diligence conducted prior to such event where permitted by law. <br />{" "}
          • Protect our rights: We may disclose personal information where we
          believe it necessary to respond to claims asserted against us, to
          enforce or administer our agreements and terms, for fraud prevention,
          risk assessment, investigation and/or to protect the rights, property
          or safety of the Companies or our affiliates, clients, customers,
          portfolio companies and/or others. <br /> Aggregated and De-identified
          Data. As discussed above, we may share aggregate or de-identified
          information with third parties for research, analytics and/or other
          purposes.
        </p>
        <br /> <br />
        <h2>05 Cookies and Similar Technologies</h2>
        <br />
        <p>
          Cookies, and similar technologies like pixels, are widely used by
          website owners in order to make their websites work, or to work more
          efficiently, as well as to provide reporting information. Cookies set
          by the website owner (in this case, the Companies) are called “first
          party cookies.” Cookies set by parties other than the website owner
          are called “third party cookies”. Third party cookies enable third
          party features or functionality to be provided on or through the
          website (e.g. like advertising, interactive content and analytics).
          The parties that set these third party cookies can recognize your
          computer both when it visits the website in question and also when it
          visits certain other websites. <br /> We use first and third party
          cookies for several reasons. Some cookies are required for technical
          reasons to operate the Site, and we refer to these as “essential” or
          “strictly necessary” cookies. Other cookies also enable us to track
          and target the interests of our users to enhance the experience on the
          Site. Third parties serve cookies through the Site for advertising,
          analytics and other purposes. This is described in more detail below.
          The specific types of first and third party cookies served through the
          Site and the purposes they perform are described below.
        </p>
        <br />
        <p>
          How can I control cookies? You have the right to decide whether to
          accept or reject cookies. You can exercise your cookie rights by
          setting your preferences in the Cookie Consent Manager. The Cookie
          Consent Manager allows you to select which categories of cookies you
          accept or reject. Essential cookies cannot be rejected as they are
          strictly necessary to provide you with services. The Cookie Consent
          Manager can be found in the notification banner and on our website. If
          you choose to reject cookies, you may still use the Site though your
          access to some functionality and areas of our website may be
          restricted. You may also set or amend your web browser controls to
          accept or refuse cookies. As the means by which you can refuse cookies
          through your web browser controls vary from browser-to-browser, you
          should visit your browser’s help menu for more information. In
          addition, most advertising networks offer you a way to opt out of
          targeted advertising. <br /> You may also opt out of having your data
          used by Google Analytics via Google’s Ads Settings, Ad Settings for
          mobile apps, or any other available means. Please visit
          policies.google.com/technologies/partner-sites for more information on
          how to do this. If you would like to find out more information, please
          visit http://www.aboutads.info/choices/ or
          http://www.youronlinechoices.com.
        </p>
        <br />
        <h3>Cookies Served on the Site. </h3>
        <br />{" "}
        <p>
          The specific types of first and third party cookies served through our
          Websites are: Essential website cookies: These cookies are strictly
          necessary to provide you with services available through the Site and
          to use some of its features, such as access to secure areas.
          Performance and functionality cookies: These cookies are used to
          enhance the performance and functionality of the Site but are
          non-essential to their use. However, without these cookies, certain
          functionality (like videos) may become unavailable. Analytics and
          customization cookies: These cookies collect information that is used
          either in aggregate form to help us understand how the Site is being
          used or how effective our marketing campaigns are, or to help us
          customize the Site for you. Advertising cookies: These cookies are
          used to make advertising messages more relevant to you. They perform
          functions like preventing the same ad from continuously reappearing,
          ensuring that ads are properly displayed for advertisers, and in some
          cases selecting advertisements that are based on your interests.
        </p>
        <br />
        <h2>06 Do Not Track</h2> <br />
        <p>
          The Site currently does not respond to Do Not Track (“DNT”) signals.
        </p>{" "}
        <br />
        <h2>07 How We Protect Your Personal Information</h2> <br />
        <p>
          We maintain internal policies and procedures as well as physical and
          electronic safeguards designed to safeguard access to personal
          information in our possession from accidental or unlawful destruction,
          loss, alteration, unauthorized use or disclosure. We endeavor to limit
          access to personal information about you to those employees, agents
          and other authorized parties who need to know the information to
          enable us to provide products or services to you. Please note that
          such safeguards, however, do not guarantee that personal information
          transmitted via the Internet or stored in our systems or is otherwise
          under our care will be absolutely safe from unauthorized use or
          intrusion from third parties due to the inherent nature of the
          Internet.
        </p>{" "}
        <br />
        <h2>08 How Long Do the Companies Keep My Data?</h2> <br />
        <p>
          We will only retain your personal data for as long as is reasonably
          necessary to fulfill the purposes that we process it for, including
          for the purposes of satisfying any legal, regulatory, tax, accounting,
          or reporting requirements. We may retain your personal data for a
          longer period in the event of a complaint, or if we reasonably believe
          there is a prospect of litigation with respect to our relationship
          with you. To determine the appropriate retention period for personal
          data, we consider the amount, nature and sensitivity of the personal
          data, the potential risk of harm from the unauthorized use or
          disclosure of your personal data, the purposes for which we process
          your personal data and whether we can achieve those purposes through
          other means, and the applicable legal, regulatory, tax, accounting, or
          other requirements. In some circumstances, we will anonymize your
          personal data (so that it can no longer be associated with you) for
          research or statistical purposes, in which case we may use this
          information indefinitely without providing further notice to you.
        </p>
        <br />
        <h2>09 Your Rights</h2> <br />
        <p>
          You may have the following rights available to you, depending on your
          jurisdiction or depending on the jurisdiction in which the data was
          collected, including the right to: Request confirmation of the
          existence of processing of your personal data, which enables you to
          know if the company carries out processing of your personal data.
          Request access to your personal data. This enables you to receive a
          copy of the personal data we hold about you. Depending on the
          applicable privacy laws, you may be entitled to request the categories
          and specific pieces of personal data that we have collected about you,
          the categories of sources from which the personal data was collected,
          the purposes of collecting the personal data, the categories of third
          parties we have shared the personal data with, and the categories of
          personal data that have been shared with third parties for a business
          purpose. Request the correction of the personal data that we hold
          about you. This enables you to have any incomplete, out-of-date,
          irrelevant, misleading, or inaccurate data we hold about you
          corrected, although we may need to verify the accuracy of the new data
          you provide to us. Request the erasure of your personal data. This
          enables you to ask us to delete or remove personal data. Note,
          however, that we may not always be able to comply with your request
          (for example, we cannot erase information if we are required to keep
          it for regulatory purposes or to fulfill an order you have placed).
          Limit use and disclosure of sensitive personal data to only those
          purposes necessary to provide our services and goods. This enables you
          to restrict the use and disclosure of your sensitive personal data,
          such as precise geolocation data. Data portability. In some instances,
          you may have the right to receive the information about you in a
          portable and readily usable format. Withdrawal of consent, at any
          time, by means of and express statement by you, through a free and
          facilitated procedure, ratified processing carried out under the
          protection of the previously expressed consent, as long as there is no
          request for erasure. Opt-out of the sale or sharing of personal data.
          For purposes of U.S. (including California) law, we do not sell your
          personal information to or share your personal information with third
          parties for cross contextual advertising, nor do we intend to, as
          those terms are defined in applicable privacy laws, including the CCPA
          and CPRA. We also have not done so for the last 12 months. We may
          include on our website third-party cookies, which allow the
          third-party cookie provider to collect data directly for their own
          purposes and subject to their own separate privacy notice. More
          information about what cookies are on our website and what they do is
          provided in the “Cookies” section above. It is your choice whether to
          accept all but strictly necessary cookies, including third-party
          cookies, when you navigate to our website. You are also free not to
          leave reviews or other user-generated content. Opt of the sharing of
          certain information with affiliates and nonaffiliates. To the extent
          applicable, Federal law gives you the right to limit only sharing with
          affiliates about your creditworthiness for their everyday business
          purposes and with both affiliates and nonaffiliates to market to you.
          State laws and individual companies may give you additional rights to
          limit sharing. You will not be charged a fee to access your data or
          exercise any of your other rights except in certain limited
          circumstances permitted under law, such as where a request is
          unfounded, repetitive, or excessive. We will not discriminate against
          you in any way for exercising your rights.
        </p>{" "}
        <br />
        <h2>10 How to Exercise Your Rights</h2> <br />
        <p>
          If you would like to access your personal information or exercise any
          of your other rights, please submit the request toinfo@Bay Capital
          Partners.com, call us at 1-212-235-2600 or contact us via the methods
          indicated below. For your protection, we may need to request specific
          information from you to help us verify your identity before we can
          respond to a request from you to exercise your rights. Where
          applicable, this is a security measure to ensure that the personal
          data is not disclosed to any person who does not have the right to
          receive it. For example, we may verify the email address we have on
          file with the email address from which you are sending a request. We
          may also contact you to ask you for further information in relation to
          your request, in order to speed up our response. You may also
          authorize someone else to make a request on your behalf. To do so, you
          must provide the agent with written, signed permission to submit
          privacy rights requests on your behalf. The agent must provide this
          authorization at the time of the request.
        </p>{" "}
        <br />
        <h2>11 Links to Third-Party Websites</h2> <br />
        <p>
          The Site may contain links to third party websites which, in some
          cases, may be integrated into, and appear to be part of, the Site. The
          Companies encourage you to read the privacy policies of other websites
          you link to from this website and disclaims any responsibility for the
          privacy policies and procedures of any third-party websites that you
          reach via a hyperlink contained on this Site. Such hyperlinks are
          provided for your convenience only, and you access them at your own
          risk.
        </p>{" "}
        <br />
        <h2>12 Use of Site by Minors</h2> <br />
        <p>
          The Site is not designed or intended to collect information from
          anyone under the age of 16. The Companies do not knowingly collect or
          maintain any information from anyone under the age of 16. The Site is
          not designed with the purpose of attracting any person under the age
          of 16. To respect the privacy of children and to comply with the
          Children’s Online Privacy Protection Act, if you are under the age of
          18, you should only use the Site with the involvement of a parent or
          guardian.
        </p>{" "}
        <br />
        <h2>13 Updates to this Privacy Policy</h2> <br />
        <p>
          The Companies reserve the right in its sole discretion to modify or
          amend this Privacy Policy in any way at any time. Any such changes are
          effective upon posting and we will indicate in the Privacy Policy when
          it was most recently updated. You should review this Privacy Policy
          periodically for changes applicable to you.
        </p>{" "}
        <br />
        <h2>14 Other Communications</h2> <br />
        <p>
          From time to time, we may email users of the Website information
          regarding important developments affecting Bay Capital Partners LLC.
          We may also email users on occasion regarding the Website. Such emails
          may be sent to users of the Website even if they have not requested to
          receive any communication from us.
        </p>{" "}
        <br />
        <h2>15 Compliance with Laws</h2> <br />
        <p>
          We reserve the right to disclose any information to comply with any
          law, regulation, decree, judgment, order, subpoena or any other
          governmental order (“Order”) without any obligation to contest or
          verify the accuracy of such Order.
        </p>{" "}
        <br />
        <br />
        <h2>16 Limitation of Liability</h2> <br />
        <p>
          UNDER NO CIRCUMSTANCES SHALL Bay Capital Partners LLC, ANY OF ITS
          AFFILIATES, OR THEIR RESPECTIVE OFFICERS, AGENTS, PARTNERS OR
          EMPLOYEES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
          CONSEQUENTIAL DAMAGES, RESULTING FROM THE USE OR THE INABILITY TO USE
          THE WEBSITE AND SERVICES; OR RESULTING FROM ANY INFORMATION OR
          SERVICES OBTAINED OR MESSAGES RECEIVED; OR RESULTING FROM UNAUTHORIZED
          ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OF DATA, INCLUDING BUT
          NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, USE, DATA OR OTHER
          INTANGIBLES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. <br /> Because some jurisdictions do not allow the exclusion
          or limitation of liability for consequential or incidental damages, in
          such states liability is limited to the fullest extent permitted by
          law. <br />
          If you are dissatisfied with any portion of the site, or with any of
          these terms of use, your sole and exclusive remedy is to discontinue
          using this site.
        </p>{" "}
        <br />
        <h2>17 Indemnification</h2> <br />
        <p>
          As a condition to your use of the Website, you agree to indemnify,
          defend and hold harmless Bay Capital Partners LLC and its respective,
          officers, directors, affiliates, partners, members, principals,
          agents, investors, employees, and third party sources from and against
          any and all suits, losses, claims, demands, liabilities, damages,
          costs and expenses (including reasonable attorneys’ fees) that arise
          from or relate to: (i) your use of the Website; (ii) your breach of
          these Terms or any representation, warranty or covenant made by you in
          these Terms; (iii) your violation of any applicable law, statute,
          ordinance, regulation or of any third party’s rights; or (iv) claims
          asserted by third parties which, if proven, would place you in breach
          of representations, warranties, covenants or other provisions
          contained in these Terms.
        </p>{" "}
        <br />
        <h2>18 Updating Your Information</h2> <br />
        <p>
          To help us keep your customer information up-to-date and accurate,
          please contact the Adviser, at the address below, if there is any
          change in your personal information. <br />
          Bay Capital Partners LLC <br />
          One Turks Head Place, Suite 503 | <br />
          Providence, RI 02903, <br />
          P (401) 396-0930, <br />
          F (401) 228-3835<br />
          info@baycapitalpartners.com
        </p>
      </div>
      <MainFooter />
    </>
  );
};

export default Policy;
