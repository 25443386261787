import React from "react";
import "../Pages/InvestorLogin.css";
import Login from "../Components/Login/Login";

const InvestorLogin = () => {
  return (
    <>
        <div className="log_sig">
          <div className="login">
            <Login />
          </div>
         
        </div>
    </>
  );
};

export default InvestorLogin;
